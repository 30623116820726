// Required libraries
// ================================================================
import React from 'react';
import PT from 'prop-types';

// Trendolizer UI components
// ================================================================
import Icon from 'trendolizer-ui/build/Icon';
import DateTime from 'trendolizer-ui/build/DateTime';

// Component declaration
// ================================================================
export function ResultFooter(props) {
  const { domain, author, found } = props;
  return (
    <footer className='Result-footer'>
      {domain ? (
        <span className='Result-footer-item'>
          <Icon icon='globe' />
          {domain}
        </span>
      ) : null}
      {author ? (
        <span className='Result-footer-item'>
          <Icon icon='feather' />
          {author}
        </span>
      ) : null}
      {found ? (
        <DateTime
          className='Result-footer-item'
          data={found}
          format='MMMM DD | HH:mm'
          icon='clock'
        />
      ) : null}
    </footer>
  );
}

export default React.memo(ResultFooter);

// PropTypes Declaration
// ================================================================
ResultFooter.propTypes = {
  domain: PT.string,
  author: PT.string,
  found: PT.string
};
