// Required libraries
// ================================================================
import React, { useCallback, useEffect, useState } from 'react';
import PT from 'prop-types';
import { createPortal } from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';

import { fetchTenant } from 'shared/Store/Tenant';
import { fetchSettings } from 'shared/Store/App';

// Store imports
// ================================================================
import { LOADING_OVERLAY_ID } from 'shared/const';
import { getSessionStatus, getNetworkError } from 'shared/Store/App';

// Component to render
// ================================================================
import LoadingOverlay from '../Components/LoadingOverlay';

const INIT_STEP = {
  index: 0,
  label: 'Application initialization'
};

const TENANT_STEP = {
  index: 1,
  label: 'App tenant authenticated'
};

const SETTINGS_STEP = {
  index: 2,
  label: 'Settings retrieved'
};

const checkCORSError = (err) => {
  if (err.message === 'Failed to fetch') {
    document.body.insertAdjacentHTML(
      'beforeend',
      '<div class="no-cors">App settings and Dashboards would not work because CORS is not configured properly for a new API. Add [Access-Control-Allow-Origin] header.</div>'
    );
  } else {
    throw err;
  }
};

// Component declaration
// ================================================================
export default function LoadingOverlayModule({ steps }) {
  const networkError = useSelector(getNetworkError);
  const session = useSelector(getSessionStatus);
  const dispatch = useDispatch();
  const [{ index, label }, setStep] = useState(INIT_STEP);
  const [loaded, setLoaded] = useState(false);
  const total = steps.length + 2;

  const runSequence = useCallback(async () => {
    await dispatch(fetchTenant()).catch(checkCORSError);
    setStep(TENANT_STEP);

    await dispatch(fetchSettings()).catch(checkCORSError);
    setStep(SETTINGS_STEP);

    await Promise.all(
      steps.map(async (step) => {
        await dispatch(step.handler()).catch(checkCORSError);
        setStep((old) => {
          // if (old.index === 4) {
          //   throw new Error('error happenee');
          // }
          return { label: step.label, index: old.index + 1 };
        });
      })
    );
  }, [dispatch, steps]);

  useEffect(() => {
    if (session && !loaded) {
      runSequence();
    }
  }, [session, loaded, runSequence]);

  if (loaded) return null;

  return createPortal(
    <LoadingOverlay
      id={LOADING_OVERLAY_ID}
      total={total}
      step={index}
      onAnimationEnd={setLoaded}
      label={label}
      networkError={networkError ? networkError.message : null}
    />,
    document.body
  );
}

// PropTypes declaration
// ================================================================
LoadingOverlayModule.propTypes = {
  steps: PT.arrayOf(
    PT.shape({
      label: PT.string.isRequired,
      handler: PT.func
    })
  )
};
