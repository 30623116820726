// Required libraries
// ================================================================
import React, { useCallback } from 'react';
import PT from 'prop-types';
import cn from 'classnames';
import { getRecipientType } from '../../util';
import { numBool, sourceType } from '../../proptypes';

// Trendolizer UI imports
// ================================================================
import Icon from 'trendolizer-ui/build/Icon';
import Button from 'trendolizer-ui/build/Button';
import TypeTag from 'trendolizer-ui/build/TypeTag';
import Indicator from 'trendolizer-ui/build/Indicator';
import Counter from 'trendolizer-ui/build/Counter';

// Styles
// ================================================================
import './style.css';

// Basic List Item wrapper
// =====================================================================
export default function Entity(props) {
  const {
    id,
    name,
    action,
    onClick,
    onDoubleClick,
    className,
    disabled,
    selected,
    actionTitle,
    actionIcon,
    children
  } = props;

  const actionHandler = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      action({ id });
    },
    [id, action]
  );

  const clickHandler = useCallback(
    (e) => {
      e.preventDefault();
      onClick({ id });
    },
    [id, onClick]
  );

  const doubleClickHandler = useCallback(
    (e) => {
      e.preventDefault();
      onDoubleClick({ id });
    },
    [id, onDoubleClick]
  );

  return (
    <li
      className={cn('Entity', className, {
        'state-disabled': disabled,
        'state-selected': selected
      })}
      data-id={id}
      aria-selected={selected || undefined}
      data-clickable={onClick || undefined}
      onClick={onClick ? clickHandler : null}
      onDoubleClick={onDoubleClick ? doubleClickHandler : null}
    >
      {children}
      <span className='Entity-label'>{name}</span>
      {action ? (
        <Button
          className='Entity-action'
          onClick={actionHandler}
          icon={actionIcon}
          title={actionTitle}
        />
      ) : null}
    </li>
  );
}

Entity.propTypes = {
  id: PT.oneOfType([PT.number, PT.string]).isRequired,
  name: PT.string.isRequired,
  disabled: PT.bool,
  className: PT.string,
  onClick: PT.func,
  onDoubleClick: PT.func,
  action: PT.func,
  actionTitle: PT.string,
  actionIcon: PT.string,
  children: PT.node
};

// Source variant
// =====================================================================
export function EntitySource({ type, children, ...props }) {
  return (
    <Entity {...props}>
      <TypeTag data={type.toLowerCase()} />
      {children}
    </Entity>
  );
}

EntitySource.propTypes = {
  ...Entity.propTypes,
  type: sourceType.isRequired
};

// Sourceset variant
// =====================================================================
export function EntitySet({ count, ...props }) {
  return (
    <Entity {...props}>
      <Counter theme='accent' data={count} />
    </Entity>
  );
}

EntitySet.propTypes = {
  ...Entity.propTypes,
  count: PT.number
};

// Recipient variant
// =====================================================================
export function EntityRecipient({ id, ...props }) {
  const type = getRecipientType(id);
  return (
    <Entity id={id} {...props}>
      <span className='Recipient-icon-holder'>
        {type ? <Icon icon={type} /> : null}
      </span>
    </Entity>
  );
}

EntityRecipient.propTypes = {
  ...Entity.propTypes,
  icon: PT.string,
  type: PT.oneOfType([PT.string, PT.bool])
};

// Column variant
// =====================================================================
export function EntityColumn({ open, ...props }) {
  return (
    <Entity {...props}>
      <Indicator data={open} />
    </Entity>
  );
}

EntityColumn.propTypes = {
  ...Entity.propTypes,
  open: numBool
};
