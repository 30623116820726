import isFunc from 'trendolizer-ui/build/util/isFunc';
import isPlainObject from 'trendolizer-ui/build/util/isPlainObject';
import * as matchers from './matchers';

function parseRule(rule) {
  const isRequired = rule.startsWith('!');
  const cleanRule = rule.replaceAll('!', '');
  const [matcher, args] = cleanRule.split('|');

  if (!isFunc(matchers[matcher])) {
    throw new Error(
      `Matcher ${matcher} not found in list of matchers for validation. Check spelling`
    );
  }

  return {
    matcher: matchers[matcher],
    args,
    isRequired
  };
}

function applyRule(rule, value, key) {
  const { matcher, args, isRequired } = rule;
  if (value === null || value === undefined) {
    if (isRequired) {
      return `${key} is required.`;
    }
    return null;
  } else {
    const result = matcher(value, args);
    if (result) {
      return `${key} ${result}`;
    }
    return null;
  }
}

function traverse(schema, input, base) {
  let errors = [];
  let result;

  if (isPlainObject(schema)) {
    const unknownKeys = Object.keys(input).filter((key) => !schema[key]);
    if (unknownKeys.length) {
      console.warn(
        `Unknown keys at [${base}] found: ${unknownKeys.join(
          ','
        )}.\nThey will be omitted.`
      );
    }
    result = {};
    Object.entries(schema).forEach(([key, rule]) => {
      const nested = traverse(rule, input[key], `${base}.${key}`);
      if (nested[0].length) {
        errors = errors.concat(nested[0]);
      }
      if (nested[1] !== undefined) {
        result[key] = nested[1];
      }
    });
  } else if (Array.isArray(schema) && schema.length >= 1) {
    if (!Array.isArray(input)) {
      if (schema[1] !== '?') {
        errors.push(`${base} should be an array`);
      }
    } else {
      result = input.map((val, i) => {
        const nested = traverse(schema[0], val, `${base}[${i}]`);
        if (nested[0].length) {
          errors = errors.concat(nested[0]);
        }
        return nested[1];
      });
    }
  } else if (typeof schema === 'string') {
    const error = applyRule(parseRule(schema), input, base);
    if (error) {
      errors.push(error);
    }
    result = input;
  }

  return [errors, result];
}

export function cast(schema, input, base) {
  // Start schema tree iteration
  // ========================================================
  const [errors, result] = traverse(schema, input, base || '');

  // If errors found, throw them
  // ========================================================
  if (errors.length) {
    throw new Error(`Validation error: ${errors.join(',\n')}`);
  }

  // Return result otherwise
  // ========================================================
  return result;
}
