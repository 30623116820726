import isFunc from 'trendolizer-ui/build/util/isFunc';
import { AsyncAction } from '../action';

// Provide extra arguments through closure
// ================================================================
export default function createAsyncMiddleware({ onError, api, apiv2 }) {
  return ({ getState, dispatch }) =>
    (next) =>
    async (action) => {
      // Check if payload is executable
      // ================================================================
      if (action instanceof AsyncAction) {
        try {
          // Execute payload creator with api injected
          // ================================================================
          const result = await action.execute({ api, apiv2, getState });
          // Proceed with a results
          // ================================================================
          return next(result);
        } catch (err) {
          // Run designated handler on Error
          // Make sure that Error inherits action type for future use
          // ================================================================
          err.type = action.type;
          if (isFunc(onError)) {
            dispatch(onError(err));
          }
          throw err;
        }
      } else {
        // If payload not a function just proceed
        // ================================================================
        return next(action);
      }
    };
}
