import React, { useCallback, useEffect, useState } from 'react';
import PT from 'prop-types';
import isPlainObject from 'trendolizer-ui/build/util/isPlainObject';
import runIfExists from 'trendolizer-ui/build/util/runIfExists';
import set from 'trendolizer-ui/build/util/set';
import FormContext from './context';
import { cast } from '../../validator';

export default function FormProvider(props) {
  const {
    formId,
    values,
    onChange,
    children,
    uniqueId,
    onSubmit,
    onReset,
    disabled,
    validation,
    renderForm,
    formClassName
  } = props;

  const [formValues, setFormValues] = useState(values);

  useEffect(() => {
    setFormValues(values);
    // Intentionally reset form values only if item ID changes,
    // this is solution for a case that somethimes selectors could return
    // new data object but with the same context, and it will fail default comarison check
    // ================================================================
  }, [uniqueId]);

  const changeHandler = useCallback(
    (change) =>
      setFormValues((oldValues) => {
        const customResult = runIfExists(onChange, { ...oldValues }, change);
        if (isPlainObject(customResult)) {
          return customResult;
        }
        return set({ ...oldValues }, change.name, change.value);
      }),
    [onChange]
  );

  const submitHandler = useCallback(
    async (e) => {
      e.preventDefault();
      e.stopPropagation();
      await onSubmit(validation ? cast(validation, formValues) : formValues);
    },
    [onSubmit, formValues, validation]
  );

  const contextValue = {
    disabled,
    values: formValues,
    onSubmit: submitHandler,
    onReset: onReset,
    setValue: changeHandler
  };

  return (
    <FormContext.Provider value={contextValue}>
      {renderForm ? (
        <form
          id={formId}
          className={formClassName}
          onSubmit={submitHandler}
          onReset={onReset}
        >
          {children}
        </form>
      ) : (
        children
      )}
    </FormContext.Provider>
  );
}

FormProvider.propTypes = {
  formId: PT.string,
  children: PT.node,
  disabled: PT.bool,
  uniqueId: PT.oneOfType([PT.string, PT.number]),
  onSubmit: PT.func.isRequired,
  onReset: PT.func,
  renderForm: PT.bool,
  onChange: PT.func,
  validation: PT.objectOf(PT.oneOfType([PT.string, PT.objectOf(PT.string)])),
  formClassName: PT.string,
  values: PT.object
};
