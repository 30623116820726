// Required libraries
// ================================================================
import React from 'react';
// import PT from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

// Utility functions and hooks
// ================================================================
import { useReduxAction } from '../hooks';
import { TENANT_SETTINGS_ID } from '../const';

// Store imports
// ================================================================
import { getTenant, updateTenant } from '../Store/Tenant';
import { getSettingsModalStatus, setSettingsDialogStatus } from '../Store/App';

// Component to render
// ================================================================
import TenantSettingsModal from '../Components/TenantSettingsModal';

// Component declaration
// ================================================================
export default function TenantSettingsModule() {
  // Get modal status and user data
  // ================================================================
  const data = useSelector(getTenant);
  const open = useSelector(getSettingsModalStatus);

  // Dispatch submit and cancel handlers
  // ================================================================
  const dispatch = useDispatch();
  const onSubmit = useReduxAction(updateTenant, dispatch);
  const onCancel = useReduxAction(setSettingsDialogStatus, dispatch, false);

  return (
    <TenantSettingsModal
      dialogId={TENANT_SETTINGS_ID}
      onSubmit={onSubmit}
      data={data}
      show={open}
      onClose={onCancel}
    />
  );
}

// PropTypes Declaration
// ================================================================
TenantSettingsModule.propTypes = {};
