// Required libraries
// ================================================================
import React from 'react';
import PT from 'prop-types';

// Constatsts imports
// ================================================================
import { MANAGEMENT_PATHS } from '../const';

// Component to render
// ================================================================
import NavigationList from '../Components/NavigationList';

const data = [
  {
    href: MANAGEMENT_PATHS.DASHBOARDS,
    name: 'Dashboards',
    icon: 'home'
  },
  {
    href: MANAGEMENT_PATHS.COLUMNS,
    name: 'Columns',
    icon: 'database'
  },
  {
    href: MANAGEMENT_PATHS.SOURCES,
    name: 'Sources',
    icon: 'list'
  },
  {
    href: MANAGEMENT_PATHS.FINGERPRINTS,
    name: 'Fingerprints',
    icon: 'fingerprint'
  },
  {
    href: MANAGEMENT_PATHS.ALERTS,
    name: 'Alerts',
    icon: 'alert'
  },
  {
    href: MANAGEMENT_PATHS.REPORTS,
    name: 'Reports',
    icon: 'report'
  },
  {
    href: MANAGEMENT_PATHS.AUTHORS,
    name: 'Authors',
    icon: 'user'
  }
];

// Component declaration
// ================================================================
export default function ManagementNavModule({ component, base }) {
  return (
    <NavigationList
      base={base}
      data={data}
      className='ManagementNav'
      component={component}
    />
  );
}

// PropTypes declaration
// ================================================================
ManagementNavModule.propTypes = {
  component: PT.func.isRequired,
  base: PT.string
};
