// Redux imports
// ================================================================
import { compose, createStore, combineReducers, applyMiddleware } from 'redux';

// Import Store modules
// ================================================================
import APP, { setSessionStatus, networkError } from './App';
import NOTIFICATIONS from './Notifications';
import { API_STORE_KEY } from '../const';

// Import middlewares
// ================================================================
import createAsyncMiddleware from './middleware/async';
import normalizationMiddleware from './middleware/normalize';

// Redux devtools compose replacement
// ================================================================
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// Error handler, detect 401 error separately
// ================================================================
const onError = (err) => {
  if (err.session === false) {
    return setSessionStatus(false);
  } else {
    return networkError(err);
  }
};

// Redux store factory, requires api provider and set of modules
// ================================================================
export default function createAppStore({ modules, api, apiv2 }) {
  let normalizerConfig = {};
  const reducers = [APP, NOTIFICATIONS, ...modules].reduce(
    (acc, { reducer, dataschema, key }) => {
      if (dataschema) {
        normalizerConfig = { ...normalizerConfig, ...dataschema };
      }

      acc[key] = reducer;
      return acc;
    },
    {}
  );

  const store = createStore(
    combineReducers(reducers),
    {},
    composeEnhancers(
      applyMiddleware(
        createAsyncMiddleware({ api, apiv2, onError }),
        normalizationMiddleware(normalizerConfig)
      )
    )
  );

  store[API_STORE_KEY] = {
    api,
    apiv2
  };

  return store;
}
