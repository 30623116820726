// Required libraries
// ================================================================
import React, { useCallback } from 'react';
import PT from 'prop-types';
import cn from 'classnames';
import { useDispatch } from 'react-redux';

// Store imports
// ================================================================
import { logOut } from '../Store/Tenant';

// Component to render
// ================================================================
import Button from 'trendolizer-ui/build/Button';

// Component declaration
// ================================================================
export default function LogoutButtonModule({ className, successRedirect }) {
  // Dispatch logout handler and open settings
  // ================================================================
  const dispatch = useDispatch();
  const logOutHandler = useCallback(async () => {
    await dispatch(logOut());
    if (successRedirect) {
      window.location.replace(successRedirect);
    }
  }, [dispatch, successRedirect]);

  return (
    <Button
      className={cn('LogoutButton', className)}
      onClick={logOutHandler}
      title='Log out'
      icon='logout'
      text='Log out'
    />
  );
}

// Proptypes declaration
// ================================================================
LogoutButtonModule.propTypes = {
  successRedirect: PT.string,
  className: PT.string
};
