import { APP_ENTITY_TYPES } from '../const';

export const APP = {
  KEY: APP_ENTITY_TYPES.APP,
  SESSION_STATUS: `${APP_ENTITY_TYPES.APP}/SESSION_STATUS`,
  NETWORK_ERROR: `${APP_ENTITY_TYPES.APP}/NETWORK_ERROR`,
  SETTINGS_DIALOG_STATUS: `${APP_ENTITY_TYPES.APP}/SETTINGS_DIALOG_STATUS`,
  PASSRESET_SENT: `${APP_ENTITY_TYPES.APP}/PASSRESET_SENT`,
  SIDEBAR_STATE: `${APP_ENTITY_TYPES.APP}/SIDEBAR_STATE`,
  FETCH_SETTINGS: `${APP_ENTITY_TYPES.APP}/FETCH_SETTINGS`,
  SET_DELETE_PENDING: `${APP_ENTITY_TYPES.APP}/SET_DELETE_PENDING`
};

export const NOTIFICATION = {
  KEY: APP_ENTITY_TYPES.NOTIFICATION,
  ADD: `${APP_ENTITY_TYPES.NOTIFICATION}/ADD`,
  HIDE: `${APP_ENTITY_TYPES.NOTIFICATION}/HIDE`
};

export const DASHBOARD = {
  KEY: APP_ENTITY_TYPES.DASHBOARD,
  FETCH: `${APP_ENTITY_TYPES.DASHBOARD}/FETCH`,
  CREATE: `${APP_ENTITY_TYPES.DASHBOARD}/CREATE`,
  UPDATE: `${APP_ENTITY_TYPES.DASHBOARD}/UPDATE`,
  DELETE: `${APP_ENTITY_TYPES.DASHBOARD}/DELETE`,
  SORT: `${APP_ENTITY_TYPES.DASHBOARD}/SORT`,
  CURRENT_SORT_COLUMNS: `${APP_ENTITY_TYPES.DASHBOARD}/CURRENT/SORT_COLUMNS`,
  SAVE_COLUMN_ORDER: `${APP_ENTITY_TYPES.DASHBOARD}/SAVE_ORDER`
};

export const TENANT = {
  KEY: APP_ENTITY_TYPES.TENANT,
  FETCH: `${APP_ENTITY_TYPES.TENANT}/FETCH`,
  LOGIN: `${APP_ENTITY_TYPES.TENANT}/LOGIN`,
  LOGOUT: `${APP_ENTITY_TYPES.TENANT}/LOGOUT`,
  UPDATE: `${APP_ENTITY_TYPES.TENANT}/UPDATE`,
  CREATE: `${APP_ENTITY_TYPES.TENANT}/CREATE`
};

export const COLUMN = {
  KEY: APP_ENTITY_TYPES.COLUMN,
  FETCH: `${APP_ENTITY_TYPES.COLUMN}/FETCH`,
  CREATE: `${APP_ENTITY_TYPES.COLUMN}/CREATE`,
  UPDATE: `${APP_ENTITY_TYPES.COLUMN}/UPDATE`,
  UPDATE_NAME: `${APP_ENTITY_TYPES.COLUMN}/UPDATE_NAME`,
  UPDATE_DATA: `${APP_ENTITY_TYPES.COLUMN}/UPDATE_DATA`,
  UPDATE_VISIBILITY: `${APP_ENTITY_TYPES.COLUMN}/UPDATE_VISIBILITY`,
  DELETE: `${APP_ENTITY_TYPES.COLUMN}/DELETE`
};

export const RESULT = {
  KEY: APP_ENTITY_TYPES.RESULT,
  FETCH: `${APP_ENTITY_TYPES.RESULT}/FETCH`,
  FETCH_ITEM: `${APP_ENTITY_TYPES.RESULT}/FETCH_ITEM`,
  FETCH_DETAILS: `${APP_ENTITY_TYPES.RESULT}/FETCH_DETAILS`,
  CLEAR_DETAILS: `${APP_ENTITY_TYPES.RESULT}/CLEAR_DETAILS`,
  FETCH_GRAPH_DATA: `${APP_ENTITY_TYPES.RESULT}/FETCH_GRAPH_DATA`,
  FAVORITE: `${APP_ENTITY_TYPES.RESULT}/FAVORITE`,
  IGNORE: `${APP_ENTITY_TYPES.RESULT}/IGNORE`
};

export const USER = {
  KEY: APP_ENTITY_TYPES.USER,
  FETCH: `${APP_ENTITY_TYPES.USER}/FETCH`,
  FETCH_ALL: `${APP_ENTITY_TYPES.USER}/FETCH_ALL`
};

export const SOURCESET = {
  KEY: APP_ENTITY_TYPES.SOURCESET,
  FETCH: `${APP_ENTITY_TYPES.SOURCESET}/FETCH`,
  CREATE: `${APP_ENTITY_TYPES.SOURCESET}/CREATE`,
  UPDATE: `${APP_ENTITY_TYPES.SOURCESET}/UPDATE`,
  DELETE: `${APP_ENTITY_TYPES.SOURCESET}/DELETE`,
  ADD_SOURCE: `${APP_ENTITY_TYPES.SOURCESET}/ADD_SOURCE`,
  REMOVE_SOURCE: `${APP_ENTITY_TYPES.SOURCESET}/REMOVE_SOURCE`
};

export const SOURCE = {
  KEY: APP_ENTITY_TYPES.SOURCE,
  FETCH: `${APP_ENTITY_TYPES.SOURCE}/FETCH`,
  CREATE: `${APP_ENTITY_TYPES.SOURCE}/CREATE`,
  DELETE: `${APP_ENTITY_TYPES.SOURCE}/DELETE`,
  TEST: `${APP_ENTITY_TYPES.SOURCE}/TEST`
};

export const ALERT = {
  KEY: APP_ENTITY_TYPES.ALERT,
  FETCH: `${APP_ENTITY_TYPES.ALERT}/FETCH`,
  CREATE: `${APP_ENTITY_TYPES.ALERT}/CREATE`,
  UPDATE: `${APP_ENTITY_TYPES.ALERT}/UPDATE`,
  UPDATE_STATUS: `${APP_ENTITY_TYPES.ALERT}/UPDATE_STATUS`,
  DELETE: `${APP_ENTITY_TYPES.ALERT}/DELETE`
};

export const REPORT = {
  KEY: APP_ENTITY_TYPES.REPORT,
  FETCH: `${APP_ENTITY_TYPES.REPORT}/FETCH`,
  CREATE: `${APP_ENTITY_TYPES.REPORT}/CREATE`,
  UPDATE: `${APP_ENTITY_TYPES.REPORT}/UPDATE`,
  UPDATE_STATUS: `${APP_ENTITY_TYPES.REPORT}/UPDATE_STATUS`,
  DELETE: `${APP_ENTITY_TYPES.REPORT}/DELETE`
};

export const FINGERPRINT = {
  KEY: APP_ENTITY_TYPES.FINGERPRINT,
  TEST_DOMAIN: `${APP_ENTITY_TYPES.FINGERPRINT}/TEST_DOMAIN`,
  GET_DOMAINS: `${APP_ENTITY_TYPES.FINGERPRINT}/GET_DOMAINS`,
  FETCH_ALERTS: `${APP_ENTITY_TYPES.FINGERPRINT}/FETCH_ALERTS`,
  CREATE_ALERT: `${APP_ENTITY_TYPES.FINGERPRINT}/CREATE_ALERT`,
  UPDATE_ALERT: `${APP_ENTITY_TYPES.FINGERPRINT}/UPDATE_ALERT`,
  DELETE_ALERT: `${APP_ENTITY_TYPES.FINGERPRINT}/DELETE_ALERT`
};

export const AUTHOR = {
  KEY: APP_ENTITY_TYPES.AUTHOR,
  FETCH: `${APP_ENTITY_TYPES.AUTHOR}/FETCH`
};
