import isFunc from 'trendolizer-ui/build/util/isFunc';
import runIfExists from 'trendolizer-ui/build/util/runIfExists';
import { mapArrayToData } from '../../util';
import { DATA_KEY, ORDER_KEY } from '../../const';

export const getStore = (key) => {
  return (store) => {
    if (!store[key]) {
      throw new Error(
        `Module: ${key} not found within the store.
        Looks like you try select data of module that wasn't added to store upon its instantiation.
        Check store init configuration [modules] array.
        Ususally it's located in %appname%/index.js file where [react-dom] renders application`
      );
    }
    return store[key];
  };
};

export const getAll = (state, filter, mapper) => {
  let result = mapArrayToData(state[DATA_KEY], state[ORDER_KEY]);

  if (isFunc(mapper)) {
    result = result.map(mapper);
  }

  if (isFunc(filter)) {
    result = result.filter(filter);
  }

  return result;
};

export const getOne = (state, id, fallback, mapper) => {
  const result = state[DATA_KEY][id] || fallback;
  return runIfExists(mapper, result, fallback) || result;
};

export const getSomeItems = (store, ids, mapper) => {
  if (!ids || !ids.length) return [];
  let result = mapArrayToData(store[DATA_KEY], ids);
  return isFunc(mapper) ? result.map(mapper) : result;
};
