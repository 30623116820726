import invert from 'trendolizer-ui/build/util/invert';
import invalid from 'trendolizer-ui/build/util/invalid';
import collectionSort from 'trendolizer-ui/build/util/collectionSort';

import { COLUMN } from './types';
import createReducer, {
  updateKey,
  defaultSetAll,
  defaultSetOne,
  defaultCreate,
  defaultUnsetOne
} from './reducer';
import { getStore, getAll, getSomeItems, getOne } from './selector';

import { mapOwnerId } from './Users';
import {
  INIT_VALUE,
  composeColumnParam as composeDisplaySettings
} from '../DisplaySettings';
import { LANGUAGES, ORDER_KEY } from '../const';

import createAction from './action';

export const initColumnData = {
  sort: 'rate_likes',
  direction: 'desc',
  limit: 30,
  infinite: 1
};

export const blankColumnData = {
  infinite: 0,
  show_ignored: 0,
  show_favorites: 0,
  set: [],
  source: [],
  ignore_set: [],
  ignore_source: [],
  is_video: 'auto',
  is_image: 'auto',
  is_gallery: 'auto',
  is_facebook: 'auto',
  search: '',
  exclude: '',
  'LIKE(url)': '',
  'NOTLIKE(url)': '',
  author: '',
  since: null,
  before: null,
  language: null
};

export const initDisplaySettings = [...INIT_VALUE];

export const blankColumn = {
  id: null,
  name: '',
  open: 0,
  data: { ...initColumnData },
  display_settings: initDisplaySettings
};

const decomposeColumnData = (data = {}) => {
  const processed = { ...initColumnData };

  const custom_filter = {};

  const CUSTOM_FILTER_REGEX = /MIN|MAX/;
  const ARRAY_KEY_REGEX = /set|source/;
  Object.entries(data).forEach(([key, val]) => {
    if (CUSTOM_FILTER_REGEX.test(key)) {
      custom_filter[key] = val;
    } else if (key.includes('LIKE(url)') && val) {
      processed[key] = val.replaceAll('%', '');
    } else if (ARRAY_KEY_REGEX.test(key) && Number.isInteger(val)) {
      processed[key] = [val];
    } else if (val !== undefined && val !== '') {
      processed[key] = val;
    }
  });

  return {
    processed,
    custom_filter
  };
};

export const composeColumnData = (current = {}, change = {}) => {
  return Object.entries({ ...current, ...change }).reduce(
    (acc, [key, val]) => {
      const isValidValue = val !== null && val !== '';
      const isResultTypeParam = key.startsWith('is_');
      if (isResultTypeParam && val === 'auto') {
        delete acc[key];
      } else if (key === 'direction') {
        acc[key] = val.toLowerCase();
      } else if (Array.isArray(val) && val.length) {
        acc[key] = val;
      } else if (isValidValue) {
        if (key.includes('LIKE(url)')) {
          acc[key] = !val.startsWith('%') ? `%${val}%` : val;
        } else {
          acc[key] = val;
        }
      }
      return acc;
    },
    { ...initColumnData }
  );
};

// Selectors
// ================================================================
export const getResultType = ({
  is_image,
  is_video,
  is_facebook,
  is_gallery
}) => {
  if (is_image === 1) {
    return 'Images';
  } else if (is_video === 1) {
    return 'Videos';
  } else if (is_facebook === 1) {
    return 'Facebook posts';
  } else if (is_gallery === 1) {
    return 'Galleries';
  } else if (is_image === 0) {
    return 'not Images';
  } else if (is_video === 0) {
    return 'not Videos';
  } else if (is_facebook === 0) {
    return 'not Facebook posts';
  } else if (is_gallery === 0) {
    return 'not Galleries';
  }
  return 'Anything';
};

export const getColumnsState = getStore(COLUMN.KEY);

export const getColumnsArray = (filter) => (store) => {
  const mapperFunc = mapOwnerId(store);
  return getAll(
    getColumnsState(store),
    filter,
    ({ id, data, name, owner_id, open }) => ({
      id,
      name,
      open,
      language: data.language,
      limit: data.limit,
      sort: data.sort,
      owner_id,
      direction: data.direction,
      type: getResultType(data),
      ...mapperFunc({ owner_id })
    })
  );
};

export const getColumnsById = (ids, mapper) => (store) => {
  return getSomeItems(getColumnsState(store), ids, mapper);
};

export const getColumn = (id, mapper) => (store) => {
  const column = getOne(getColumnsState(store), id, blankColumn, mapper);
  return column;
};

export const getData = (id) => (store) => {
  const { data, custom_filter } = getColumn(id)(store);
  return composeColumnData(data, custom_filter);
};

export const getColumnFeedsStatus = (id) => (store) => {
  const { data } = getColumn(id)(store);
  return {
    hasFeeds:
      (data.set && data.set.length) || (data.source && data.source.length),
    hasIgnored:
      (data.ignore_set && data.ignore_set.length) ||
      (data.ignore_source && data.ignore_source.length),
    set: data.set ? data.set.length : 0,
    source: data.source ? data.source.length : 0,
    ignore_set: data.ignore_set ? data.ignore_set.length : 0,
    ignore_source: data.ignore_set ? data.ignore_set.length : 0
  };
};

export const getDisplaySettings = (id) => (store) => {
  return getColumn(id, ({ display_settings }) => display_settings || [])(store);
};

export const getStatus = (id) => (store) => {
  const { open } = getColumn(id)(store);
  return open;
};

export const getSorting = (id) => (store) =>
  getColumn(id, ({ data }) => data.sort)(store);

export const getInfinite = (id) => (store) => {
  return getColumn(id, ({ data }) => data.infinite)(store);
};

export const getColumnIndex = (id) => (store) => {
  const state = getColumnsState(store);
  return state[ORDER_KEY].indexOf(id);
};

// Actions
// ================================================================

// Fetch all columns for current tenant
// @return Array(Object)
// ================================================================
export const fetchColumns = createAction(COLUMN.FETCH, async ({ api }) => {
  const result = await api.get('columns', { data: true });
  return collectionSort(result, 'order', 'asc').map(
    ({ id, name, open, owner_id, display_settings, data }) => {
      const { processed, custom_filter } = decomposeColumnData(data);
      return {
        ...blankColumn,
        id,
        name,
        open,
        owner_id,
        display_settings: composeDisplaySettings(display_settings),
        data: processed,
        custom_filter
      };
    }
  );
});

// Create new column
// ================================================================
export const createColumn = createAction(
  COLUMN.CREATE,
  async ({ api }, { name, data, custom_filter, display_settings }) => {
    invalid(name && name.length, 'Name is required for column creation');
    const { id, owner_id, open } = await api.post('column', { name });
    const preparedData = composeColumnData(data, custom_filter);
    const preparedDisplaySettings = composeDisplaySettings(display_settings);
    await api.put('column', {
      id,
      display_settings: preparedDisplaySettings,
      data: preparedData
    });
    return {
      payload: {
        id,
        name,
        owner_id,
        open,
        data: preparedData,
        custom_filter,
        display_settings: preparedDisplaySettings
      },
      message: `New column: "${name}" created.`
    };
  }
);

// Update column
// ================================================================
export const updateColumn = createAction(
  COLUMN.UPDATE,
  async (
    { api },
    { id, name, data, open, display_settings, custom_filter }
  ) => {
    invalid(id, 'To update Column, provide correct ID');
    const preparedData = composeColumnData(data, custom_filter);
    await api.put('column', { id, name, data: preparedData, display_settings });
    return {
      payload: { id, name, data, open, custom_filter, display_settings },
      message: `Column "${name}" was updated.`
    };
  }
);

// Update column name
// ================================================================
export const updateColumnName = createAction(
  COLUMN.UPDATE_NAME,
  async ({ api }, payload) => {
    invalid(payload.id, 'To update Column name, provide correct ID');
    invalid(payload.name, 'To update Column name, provide new name please');
    const { success } = await api.put('column', payload);
    return { payload, message: success };
  }
);

// Update column visiblity
// ================================================================
export const updateColumnVisibility = createAction(
  COLUMN.UPDATE_VISIBILITY,
  async ({ api, getState }, id) => {
    invalid(id, 'To update Column visiblity, provide correct ID');
    const open = invert(getStatus(id)(getState()));
    await api.put('column', { id, open });
    return {
      payload: { id, open },
      message: open
        ? 'Column is now visible on dashboards.'
        : 'Column no longer appears on a dashboards.'
    };
  }
);

// Do partial update of particular column data
// ================================================================
export const updateColumnData = createAction(
  COLUMN.UPDATE_DATA,
  async ({ api, getState }, { id, data }) => {
    invalid(id, 'To update Column, provide correct ID');
    invalid(data, 'New column settings should be provided as [data] prop');
    const current = getColumn(id)(getState());
    const preparedData = composeColumnData(
      { ...current.data, ...current.custom_filter },
      data
    );
    const payload = {
      id,
      data: preparedData
    };
    await api.put('column', payload);
    return {
      payload,
      message: `Column ${current.name} settings was updated.`
    };
  }
);

// Delete column request
// ================================================================
export const deleteColumn = createAction(
  COLUMN.DELETE,
  async ({ api }, { id }) => {
    invalid(id, 'To delete Column, provide correct ID');
    const { success } = await api.delete('column', { id });
    return {
      payload: { id },
      message: success
    };
  }
);

// Set column being available for results re-fetching
// ================================================================
export const forceUpdate = createAction(COLUMN.FORCE_UPDATE);

// Set column state that it's results is up-to-date
// ================================================================
export const cancelUpdate = createAction(COLUMN.CANCEL_UPDATE);

// Default module export
// ================================================================
const columnDataSchema = {
  sort: '!string',
  direction: '!oneof|desc,asc',
  limit: '!number',
  infinite: '!numbool',
  set: ['number', '?'],
  source: ['number', '?'],
  ignore_set: ['number', '?'],
  ignore_source: ['number', '?'],
  is_video: 'numboolindt',
  is_image: 'numboolindt',
  is_gallery: 'numboolindt',
  is_facebook: 'numboolindt',
  show_ignored: 'numbool',
  show_favorites: 'numbool',
  language: `oneof|${LANGUAGES.map(({ value }) => value).join(',')}`,
  search: 'string',
  exclude: 'string',
  author: 'string',
  'LIKE(url)': 'string',
  'NOTLIKE(url)': 'string',
  since: 'number',
  before: 'number'
};
export const SCHEMA = {
  id: '!number',
  name: '!string',
  open: '!numbool',
  data: columnDataSchema,
  display_settings: ['string'],
  owner_id: 'number',
  custom_filter: 'object|number'
};

export default {
  key: COLUMN.KEY,
  // Schema of entity for data normalization
  // ================================================================
  dataschema: {
    [COLUMN.FETCH]: SCHEMA,
    [COLUMN.CREATE]: SCHEMA,
    [COLUMN.UPDATE]: SCHEMA,
    [COLUMN.UPDATE_NAME]: { id: '!number', name: '!string' },
    [COLUMN.UPDATE_VISIBILITY]: { id: '!number', open: '!numbool' },
    [COLUMN.UPDATE_DATA]: { id: '!number', data: columnDataSchema },
    [COLUMN.DELETE]: { id: '!number' }
  },
  // Reducer function
  // ================================================================
  reducer: createReducer({
    [COLUMN.FETCH]: defaultSetAll,
    [COLUMN.CREATE]: defaultCreate,
    [COLUMN.UPDATE]: defaultSetOne,
    [COLUMN.UPDATE_NAME]: updateKey('DATA.$id.name', (_, { name }) => name),
    [COLUMN.UPDATE_VISIBILITY]: updateKey(
      'DATA.$id.open',
      (_, { open }) => open
    ),
    [COLUMN.UPDATE_DATA]: updateKey('DATA.$id.data', (_, { data }) => data),
    [COLUMN.DELETE]: defaultUnsetOne
  })
};
