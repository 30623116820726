import React from 'react';
import PT from 'prop-types';
import { NOTIFICATION_TYPES } from 'trendolizer-ui/build/const';
import Notification from 'trendolizer-ui/build/Notification';

// Styles
// ================================================================
import './style.css';

export default function Notifications(props) {
  const { data, onClick, delay } = props;
  return (
    <div className='Notifications'>
      {data.map(({ id, title, text, type }) => (
        <Notification
          id={id}
          key={id}
          type={type}
          title={title}
          onClick={onClick}
          delay={
            type === NOTIFICATION_TYPES.LOADING ||
            type === NOTIFICATION_TYPES.ERROR
              ? null
              : delay
          }
        >
          {text}
        </Notification>
      ))}
    </div>
  );
}

Notifications.propTypes = {
  data: PT.arrayOf(
    PT.shape({
      id: PT.number.isRequired,
      title: PT.string,
      text: PT.string,
      type: PT.oneOf(Object.values(NOTIFICATION_TYPES)).isRequired
    })
  ),
  delay: PT.number,
  onClick: PT.func.isRequired
};
