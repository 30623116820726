import runIfExists from 'trendolizer-ui/build/util/runIfExists';
import invalid from 'trendolizer-ui/build/util/invalid';
import createAction from './action';
import { USER } from './types';
import createReducer, { defaultMerge, defaultCreate } from './reducer';
import { getStore, getOne } from './selector';
import { getTenant } from './Tenant';

// Actions
// ================================================================
export const fetchUser = createAction(USER.FETCH, ({ api }, { id }) => {
  invalid(id, 'Valid ID required to fetch a user');
  return api.get('user', { id });
});

export const fetchUsers = createAction(USER.FETCH_ALL, ({ api }, { ids }) => {
  invalid(
    Array.isArray(ids),
    'To fetch a users [id] should be an array of numbers'
  );
  return Promise.all(ids.map((id) => api.get('user', { id })));
});

// Default module export
// ================================================================
const userSchema = {
  id: '!number',
  fullname: '!string',
  image: 'string',
  position: 'string'
};

export default {
  key: USER.KEY,
  // Schema of entity for data normalization
  // ================================================================
  dataschema: {
    [USER.FETCH]: userSchema,
    [USER.FETCH_ALL]: userSchema
  },
  // Reducer function
  // ================================================================
  reducer: createReducer({
    [USER.FETCH]: defaultCreate,
    [USER.FETCH_ALL]: defaultMerge
  })
};

// Selectors
// ================================================================
export const getUsersState = getStore(USER.KEY);

export const getUser = (id, mapper) => (store) => {
  const tenant = getTenant(store);
  let result = null;
  if (id === tenant.id) {
    result = tenant;
  } else {
    result = getOne(getUsersState(store), id, {});
  }
  return runIfExists(mapper, result) || result;
};

export const mapOwnerId = (store) => (item) => ({
  ...item,
  owner: getUser(item.owner_id)(store)
});
