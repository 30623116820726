// Required libraries
// ================================================================
import React from 'react';
import PT from 'prop-types';

// Hook, utils and const imports
// ================================================================
import { fullTenantShape } from '../../proptypes';

// Trendolizer UI imports
// ================================================================
import TextInput from 'trendolizer-ui/build/TextInput';
import TextArea from 'trendolizer-ui/build/TextArea';
import ImageUpload from 'trendolizer-ui/build/ImageUpload';

// Component imports
// ================================================================
import { Field } from '../Form';
import DialogForm from '../DialogForm';
import { RecipientFormField } from '../RecipientList';

// Styles
// ================================================================
import './style.css';

const EMPTY_ARRAY = [];

const SCHEME = {
  id: '!number',
  fullname: '!string',
  position: 'string',
  image: 'string',
  header: 'string',
  footer: 'string',
  email_bcc: ['recipient']
};

// Component declaration
// ================================================================
export default function TenantSettingsModal(props) {
  const { dialogId, show, onSubmit, data, onClose } = props;
  return (
    <DialogForm
      dialogId={dialogId}
      show={show}
      title='Profile info &amp; preferences'
      maxWidth='760px'
      maxHeight='90vh'
      values={data}
      onClose={onClose}
      onSubmit={onSubmit}
      validation={SCHEME}
      successText='Your profile data updated successfully.'
      className='TenantSettings'
    >
      <div className='layout-cell' data-col-span={4} data-row-span={3}>
        <ImageUpload
          id='image'
          name='image'
          disabled
          className='TenantSettings-imageeditor'
          description=''
          placeholder='img/user-placeholder.svg'
          onChange={console.log}
        />
      </div>
      <div className='layout-cell' data-col-span={8}>
        <Field
          id='fullname'
          name='fullname'
          type='text'
          label='Fullname'
          component={TextInput}
        />
      </div>
      <div className='layout-cell' data-col-span={8}>
        <Field
          id='position'
          name='position'
          type='text'
          label='Position'
          component={TextInput}
        />
      </div>
      <div
        className='layout-cell'
        data-col-span={8}
        data-col-start={5}
        role='group'
      >
        <Field
          id='email_bcc'
          name='email_bcc'
          className='TenantSettings-recipients'
          label='Additional recipients'
          defaultValue={EMPTY_ARRAY}
          component={RecipientFormField}
          message={`All alerts will be sent to the main email address ${data.email} associated with this account. Use the form above to add an extra recipient.`}
        />
      </div>
      <div className='layout-cell'>
        <Field
          id='header'
          name='header'
          rows={6}
          label='Custom alert/report email header'
          component={TextArea}
        />
      </div>
      <div className='layout-cell'>
        <Field
          id='footer'
          name='footer'
          rows={6}
          label='Custom alert/report email footer'
          component={TextArea}
        />
      </div>
    </DialogForm>
  );
}

// PropTypes declaration
// ================================================================
TenantSettingsModal.propTypes = {
  dialogId: PT.string.isRequired,
  show: PT.bool,
  data: PT.shape(fullTenantShape),
  onClose: PT.func.isRequired,
  onSubmit: PT.func.isRequired
};
