import React from 'react';
import PT from 'prop-types';
import cn from 'classnames';

import Icon from 'trendolizer-ui/build/Icon';

// Styles
// ================================================================
import './style.css';

export default function StatusMessage(props) {
  const { className, success, error, warning, text, onClick } = props;
  let icon = 'info';
  if (error) {
    icon = 'error';
  } else if (warning) {
    icon = 'warning';
  } else if (success) {
    icon = 'success';
  }

  return (
    <div
      className={cn('StatusMessage', className, {
        'state-error': error,
        'state-warning': warning && !error,
        'state-success': success && !warning && !error
      })}
      onClick={onClick}
    >
      <Icon className='StatusMessage-icon' icon={icon} />
      <p className='StatusMessage-text'>
        {error || warning || success || text}
      </p>
    </div>
  );
}

StatusMessage.propTypes = {
  onClick: PT.func,
  className: PT.string,
  success: PT.string,
  warning: PT.string,
  text: PT.string,
  error: PT.string
};
