// Required imports
// ================================================================
import React from 'react';
import PT from 'prop-types';
import cn from 'classnames';

// Style and image imports
// ================================================================
import './style.css';

// Text hashmap
// ================================================================
const TEXTS = {
  true: ['Loading results...', 'Results is on the way to you'],
  false: [
    'It seems we could not find any items matching your query at this time.',
    'Try again later or modify the filter settings for this column.'
  ]
};

// Component declaration
// ================================================================
export default function ResultStatus({ loading, error }) {
  const errorTitle = 'Ooups! Something went wrong';
  return (
    <section
      className={cn('ResultStatus', {
        'state-error': !!error,
        'state-loading': loading
      })}
    >
      <svg viewBox='0 0 842 1220' className='ResultStatus-image'>
        {!loading && !error ? (
          <g id='questions' className='ResultStatus-image-outer'>
            <path d='m623.45 250.62c0 8.8798-7.181 16.086-16.08 16.086-8.8669 0-16.08-7.2068-16.08-16.086s7.2132-16.087 16.08-16.087c8.8991 0 16.08 7.2003 16.08 16.087zm-15.572-138.34c-25.925 0-45.428 17.328-45.428 51.477h25.449c0-14.825 5.8298-26.955 19.458-26.955 8.024 0 16.537 5.3278 17.27 15.514 0.79145 10.72-4.9354 16.157-12.174 23.049-18.815 17.888-18.12 26.054-18.12 46.303h25.372c0-9.343-1.0102-16.138 11.827-29.979 8.5645-9.2401 19.214-20.732 19.439-38.241 0.30243-25.5-17.702-41.169-43.093-41.169z' />
            <path d='m115.41 889.16c0 6.9065-5.5852 12.512-12.507 12.512-6.8965 0-12.507-5.6052-12.507-12.512 0-6.9065 5.6103-12.512 12.507-12.512 6.9215 0 12.507 5.6002 12.507 12.512zm-12.111-107.6c-20.164 0-35.333 13.478-35.333 40.038h19.794c0-11.531 4.5343-20.965 15.134-20.965 6.2408 0 12.862 4.1439 13.433 12.066 0.61557 8.3378-3.8386 12.567-9.4689 17.927-14.634 13.913-14.093 20.264-14.093 36.014h19.734c0-7.2668-0.78573-12.552 9.1986-23.317 6.6612-7.1867 14.944-16.125 15.119-29.743 0.23523-19.834-13.768-32.02-33.516-32.02z' />
            <path d='m677.39 1121.9c0 10.853-8.7768 19.661-19.653 19.661-10.837 0-19.653-8.8082-19.653-19.661s8.8161-19.661 19.653-19.661c10.877 0 19.653 8.8003 19.653 19.661zm-19.032-169.09c-31.686 0-55.524 21.179-55.524 62.916h31.104c0-18.12 7.1253-32.945 23.782-32.945 9.807 0 20.212 6.512 21.108 18.961 0.96733 13.102-6.0321 19.748-14.88 28.171-22.996 21.863-22.146 31.843-22.146 56.593h31.01c0-11.419-1.2347-19.724 14.455-36.641 10.468-11.294 23.483-25.34 23.759-46.739 0.36964-31.167-21.635-50.317-52.669-50.317z' />
          </g>
        ) : null}

        {error ? (
          <g id='cross' className='ResultStatus-image-cross'>
            <path d='m 727.75288,1086.3252 -44.62437,-44.0555 44.0072,-44.44725 -19.84081,-19.84081 -44.07161,44.64046 -44.59754,-44.02329 -19.67444,19.67449 44.65658,44.2218 -44.0394,44.6136 19.67444,19.6745 44.20577,-44.6406 44.46337,44.0234 z' />
            <path d='m 152.14575,875.68237 -32.78523,-32.3673 32.33181,-32.65511 -14.57692,-14.57692 -32.37913,32.79706 -32.765528,-32.34363 -14.454686,14.45472 32.808906,32.48948 -32.355468,32.77733 14.454686,14.45473 32.4777,-32.79716 32.66695,32.34371 z' />
            <path d='m 681.20437,245.51715 -53.5333,-52.85086 52.79291,-53.32081 -23.80188,-23.80189 -52.87018,53.5526 -53.50111,-52.81221 -23.6023,23.60236 53.57194,53.05035 -52.83154,53.52038 23.60229,23.60237 53.03113,-53.55277 53.34016,52.81235 z' />
          </g>
        ) : null}

        <g id='lines' className='ResultStatus-image-outer'>
          <rect x='20' y='123' width='320' height='18' rx='8' ry='8' />
          <rect x='40' y='187' width='440' height='18' rx='8' ry='8' />

          <rect x='575' y='330' width='140' height='18' rx='8' ry='8' />
          <rect x='617' y='395' width='200' height='18' rx='8' ry='8' />

          <rect x='152' y='980' width='400' height='18' rx='8' ry='8' />
          <rect x='60' y='1042' width='380' height='18' rx='8' ry='8' />
        </g>

        <g transform='rotate(-15 1162 677)'>
          <g className='ResultStatus-doc1'>
            <rect
              x='260'
              y='150'
              width='340'
              height='460'
              rx='8'
              ry='8'
              className='ResultStatus-image-base'
            />
            <rect
              x='280'
              y='160'
              width='300'
              height='400'
              rx='8'
              ry='8'
              fill='#fff'
            />
            <g id='clamp1' className='ResultStatus-image-clamp'>
              <path d='m430.15 112.76a16 16 0 0 0-16 16 16 16 0 0 0 16 16 16 16 0 0 0 16-16 16 16 0 0 0-16-16zm-0.0469 6.2547a9.7455 9.7455 0 0 1 0.0469 0 9.7455 9.7455 0 0 1 9.7453 9.7453 9.7455 9.7455 0 0 1-9.7453 9.7453 9.7455 9.7455 0 0 1-9.7453-9.7453 9.7455 9.7455 0 0 1 9.6984-9.7453z' />
              <rect x='310' y='140' width='240' height='36' rx='4' ry='4' />
            </g>
            <g id='text1' className='ResultStatus-image-text'>
              <rect
                x='290'
                y='192'
                width='280'
                height='8'
                rx='2'
                ry='2'
                fillOpacity='.5'
              />
              <rect x='290' y='210' width='240' height='8' rx='2' ry='2' />
              <rect
                x='290'
                y='245'
                width='280'
                height='8'
                rx='2'
                ry='2'
                fillOpacity='.5'
              />
              <rect x='290' y='262' width='280' height='8' rx='2' ry='2' />
              <rect x='290' y='280' width='280' height='8' rx='2' ry='2' />
              <rect x='290' y='298' width='180' height='8' rx='2' ry='2' />
              <rect
                x='290'
                y='332'
                width='280'
                height='8'
                rx='2'
                ry='2'
                fillOpacity='.5'
              />
              <rect
                x='290'
                y='367'
                width='280'
                height='8'
                rx='2'
                ry='2'
                fillOpacity='.5'
              />
              <rect x='290' y='385' width='280' height='8' rx='2' ry='2' />
              <rect x='290' y='402' width='220' height='8' rx='2' ry='2' />
              <rect
                x='290'
                y='437'
                width='280'
                height='8'
                rx='2'
                ry='2'
                fillOpacity='.5'
              />
              <rect x='290' y='455' width='280' height='8' rx='2' ry='2' />
              <rect x='290' y='472' width='180' height='8' rx='2' ry='2' />
              <rect x='290' y='506' width='120' height='8' rx='2' ry='2' />
              <rect x='290' y='525' width='120' height='8' rx='2' ry='2' />
            </g>
          </g>
        </g>

        <g transform='translate(85 314)'>
          <g className='ResultStatus-doc2'>
            <rect
              x='260'
              y='150'
              width='340'
              height='460'
              rx='8'
              ry='8'
              className='ResultStatus-image-base'
            />
            <rect
              x='280'
              y='160'
              width='300'
              height='400'
              rx='8'
              ry='8'
              fill='#fff'
            />
            <g id='clamp2' className='ResultStatus-image-clamp'>
              <path d='m430.15 112.76a16 16 0 0 0-16 16 16 16 0 0 0 16 16 16 16 0 0 0 16-16 16 16 0 0 0-16-16zm-0.0469 6.2547a9.7455 9.7455 0 0 1 0.0469 0 9.7455 9.7455 0 0 1 9.7453 9.7453 9.7455 9.7455 0 0 1-9.7453 9.7453 9.7455 9.7455 0 0 1-9.7453-9.7453 9.7455 9.7455 0 0 1 9.6984-9.7453z' />
              <rect x='310' y='140' width='240' height='36' rx='4' ry='4' />
            </g>
            <g id='text2' className='ResultStatus-image-text'>
              <rect
                x='290'
                y='192'
                width='280'
                height='8'
                rx='2'
                ry='2'
                fillOpacity='.5'
              />
              <rect x='290' y='210' width='240' height='8' rx='2' ry='2' />
              <rect
                x='290'
                y='245'
                width='280'
                height='8'
                rx='2'
                ry='2'
                fillOpacity='.5'
              />
              <rect x='290' y='262' width='280' height='8' rx='2' ry='2' />
              <rect x='290' y='280' width='280' height='8' rx='2' ry='2' />
              <rect x='290' y='298' width='180' height='8' rx='2' ry='2' />
              <rect
                x='290'
                y='332'
                width='280'
                height='8'
                rx='2'
                ry='2'
                fillOpacity='.5'
              />
              <rect
                x='290'
                y='367'
                width='280'
                height='8'
                rx='2'
                ry='2'
                fillOpacity='.5'
              />
              <rect x='290' y='385' width='280' height='8' rx='2' ry='2' />
              <rect x='290' y='402' width='220' height='8' rx='2' ry='2' />
              <rect
                x='290'
                y='437'
                width='280'
                height='8'
                rx='2'
                ry='2'
                fillOpacity='.5'
              />
              <rect x='290' y='455' width='280' height='8' rx='2' ry='2' />
              <rect x='290' y='472' width='180' height='8' rx='2' ry='2' />
              <rect x='290' y='506' width='120' height='8' rx='2' ry='2' />
              <rect x='290' y='525' width='120' height='8' rx='2' ry='2' />
            </g>
          </g>
        </g>
      </svg>
      <h1 className='ResultStatus-title h3'>
        {TEXTS[loading][0] || errorTitle}
      </h1>
      <h2 className='ResultStatus-subtitle h4'>{error || TEXTS[loading][1]}</h2>
    </section>
  );
}

// PropTypes Declaration
// ================================================================
ResultStatus.propTypes = {
  /** Text message of error */
  error: PT.string,
  /** Whatever loading is in process */
  loading: PT.bool
};
