// Required libraries
// ================================================================
import React from 'react';
import PT from 'prop-types';
import { useSelector, shallowEqual } from 'react-redux';

// Store imports
// ================================================================
import { getTenantInfo } from '../Store/Tenant';

// Component to render
// ================================================================
import UserInfo from '../Components/UserInfo';

// Component declaration
// ================================================================
export default function TenantInfoModule({ children }) {
  // Retrieve data for a user to show info
  // ================================================================
  const { fullname, position, image } = useSelector(
    getTenantInfo,
    shallowEqual
  );

  return (
    <UserInfo fullname={fullname} position={position} image={image}>
      {children}
    </UserInfo>
  );
}

// Proptypes declaration
// ================================================================
TenantInfoModule.propTypes = {
  children: PT.node
};
