// Required libraries
// ================================================================
import React from 'react';
import PT from 'prop-types';
import shortenNumber from 'trendolizer-ui/build/util/shortenNumber';
import formatDate from 'trendolizer-ui/build/util/formatDate';

// Trendolizer UI components
// ================================================================
import Popup from 'trendolizer-ui/build/Popup';

// Component declaration
// ================================================================
export function ReactHeader(props) {
  const { children, sort, value, found } = props;

  const [date, time] = formatDate(new Date(found), 'MMM DD|HH:mm').split('|');

  return (
    <header className='Result-header'>
      {sort === 'found' ? (
        <time
          className='Result-primary'
          dateTime={found}
          title={`Found on: ${date} at ${time}`}
        >
          <b>{time}</b>
          {date}
        </time>
      ) : (
        <div className='Result-primary' title={`${sort}: ${value}`}>
          <b>{shortenNumber(value)}</b>
          {sort}
        </div>
      )}
      {children ? (
        <Popup
          className='Result-actions'
          title='Actions to this result'
          popupClassName='Result-actions-list'
        >
          {children}
        </Popup>
      ) : null}
    </header>
  );
}

export default React.memo(ReactHeader);

// PropTypes Declaration
// ================================================================
ReactHeader.propTypes = {
  children: PT.node,
  sort: PT.string,
  value: PT.oneOfType([PT.string, PT.number]),
  found: PT.string
};
