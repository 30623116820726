// Required libraries
// ================================================================
import React, { useEffect } from 'react';
import PT from 'prop-types';

// Hooks, utils and constants imports
// ================================================================
import { useAction } from '../../hooks';
import { DELETE_CONFIRMATION_ID } from '../../const';

// Trendolizer UI Component imports
// ================================================================
import LinearProgress from 'trendolizer-ui/build/LinearProgress';
import Dialog from 'trendolizer-ui/build/Dialog';
import Button from 'trendolizer-ui/build/Button';

// Component to render
// ================================================================
import StatusMessage from '../StatusMessage';

// Styles
// ================================================================
import './style.css';

// Component declaration
// ================================================================
export default function DeleteConfirmation(props) {
  const { dialogId, show, children, title, onSubmit, onCancel } = props;

  const [submitHandler, { loading, success, reset, error }] = useAction(
    onSubmit,
    { onSuccess: onCancel }
  );

  // Reset dialog state if id is changed
  // ================================================================
  useEffect(() => {
    reset();
  }, [show, reset]);

  const disabled = loading || success;
  return (
    <Dialog
      id={dialogId}
      show={show}
      title={title}
      maxWidth='32rem'
      onClose={onCancel}
      footerCloseButton
      zIndex='calc(var(--layout-z-overlay) + 1 )'
      className='DeleteConfirmation'
      wrapContent='layout-grid'
      header={<LinearProgress loading={loading} success={success} />}
      footer={
        <Button
          disabled={disabled}
          onClick={submitHandler}
          appearance='accent'
          text='Confirm'
        />
      }
    >
      {error ? (
        <StatusMessage className='layout-cell' error={error} onClick={reset} />
      ) : null}

      <div className='layout-cell DeleteConfirmation-content'>
        <b>Warning! This action cannot be undone.</b>
        <br />
        {children}
      </div>
    </Dialog>
  );
}

// PropTypes declaration
// ================================================================
DeleteConfirmation.propTypes = {
  show: PT.bool,
  dialogId: PT.string,
  title: PT.string,
  children: PT.node,
  onSubmit: PT.func,
  onCancel: PT.func.isRequired
};

DeleteConfirmation.defaultProps = {
  dialogId: DELETE_CONFIRMATION_ID,
  title: 'Delete Confirmation'
};
