import React from 'react';
import PT from 'prop-types';
import { useSelector } from 'react-redux';

import { getColumn } from '../../Store/Columns';

export default function DeleteColumnConfirmationMessage(props) {
  const { id } = props;

  const { name } = useSelector(getColumn(id));

  return (
    <>
      You&apos;re about to delete column:&nbsp;
      <b>{name}</b>
      <br />
      Please note that column will be removed from ALL dashboards it currently
      included.
      <br />
      Also it will affect all Reports and Alerts that track this column
    </>
  );
}

DeleteColumnConfirmationMessage.propTypes = {
  id: PT.number
};
