// Required libraries
// ================================================================
import React from 'react';
import PT from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

// Stores, utils and hooks import
// ================================================================
import { useReduxAction } from '../hooks';
import { AUTHENTICATION_URL, ASIDE_NAV_ID } from '../const';

// Store imports
// ================================================================
import {
  getSidebarState,
  setSidebarState,
  setSettingsDialogStatus
} from '../Store/App';

// Trendolizer-ui imports
// ================================================================
import Button from 'trendolizer-ui/build/Button';

// Sidebar layout and nav list
// ================================================================
import LayoutSidebar from '../Components/LayoutSidebar';

// Other container required to render
// ================================================================
import LogoutButtonModule from './LogoutButton';
import TenantInfoModule from './TenantInfo';

// Component declaration
// ================================================================
export default function SidebarModule({ children, className }) {
  const dispatch = useDispatch();
  const expanded = useSelector(getSidebarState);

  // Toggle sidebar state
  // ================================================================
  const onDoubleClick = useReduxAction(setSidebarState, dispatch, !expanded);

  // Open user settings on Settings Button click
  // ================================================================
  const onSettingsClick = useReduxAction(
    setSettingsDialogStatus,
    dispatch,
    true
  );

  return (
    <LayoutSidebar
      id={ASIDE_NAV_ID}
      className={className}
      expanded={expanded}
      onDoubleClick={onDoubleClick}
    >
      <TenantInfoModule>
        <Button
          className='UserInfo-button'
          title='Open user settings'
          appearance='solid'
          icon='cog'
          onClick={onSettingsClick}
        />
      </TenantInfoModule>
      {children}
      <LogoutButtonModule successRedirect={AUTHENTICATION_URL} />
    </LayoutSidebar>
  );
}

// Proptypes declaration
// ================================================================
SidebarModule.propTypes = {
  className: PT.string,
  children: PT.node
};
