import logo from 'img/logo.svg';

// Required libraries
// ================================================================
import React, { useRef } from 'react';
import PT from 'prop-types';
import cn from 'classnames';

// Utility functions and hooks
// ================================================================
import useAnimationEnd from 'trendolizer-ui/build/hooks/useAnimationEnd';

// Trendolizer UI imports
// ================================================================
import LinearProgress from 'trendolizer-ui/build/LinearProgress';

// Styles
// ================================================================
import './style.css';

// Component declaration
// ================================================================
export default function LoadingOverlay(props) {
  const { id, step, total, label, onAnimationEnd, networkError } = props;

  const $root = useRef();
  const value = Math.round(100 / total) * step + 1;

  useAnimationEnd($root, 'fadeOut', onAnimationEnd);

  return (
    <div
      ref={$root}
      id={id}
      className={cn('LoadingOverlay', {
        'state-running': total === step
      })}
    >
      <img src={logo} alt='Trendolizer pro' className='LoadingOverlay-logo' />
      <LinearProgress
        className='LoadingOverlay-progress'
        value={value}
        text={label}
      />
      {networkError ? networkError : null}
    </div>
  );
}

// Prop types declaration
// ================================================================
LoadingOverlay.propTypes = {
  id: PT.string,
  label: PT.string,
  total: PT.number.isRequired,
  step: PT.number.isRequired,
  networkError: PT.string,
  onAnimationEnd: PT.func.isRequired
};
