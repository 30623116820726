// Required libraries
// ================================================================
import React, { useCallback } from 'react';
import cn from 'classnames';
import PT from 'prop-types';

// Hook, utils and const imports
// ================================================================
import { sortDirectionShape } from 'trendolizer-ui/build/proptypes';
import { SORT_DIR_DESC, SORT_DIR_ASC } from 'trendolizer-ui/build/const';
import runIfExists from 'trendolizer-ui/build/util/runIfExists';

// Trendolizer UI imports
// ================================================================
import Button from 'trendolizer-ui/build/Button';

// Styles
// ================================================================
import './style.css';

// Component declaration
// ================================================================
export default function DirectionIndicator(props) {
  const { id, name, onChange, value, className, disabled } = props;
  const changeHandler = useCallback(
    (e) => {
      runIfExists(e.preventDefault);
      onChange({
        id,
        name,
        value: value === SORT_DIR_ASC ? SORT_DIR_DESC : SORT_DIR_ASC
      });
    },
    [id, name, value, onChange]
  );
  return (
    <Button
      id={id}
      name={name}
      appearance='solid'
      onClick={changeHandler}
      disabled={disabled}
      value={value}
      type='button'
      className={cn('DirectionIndicator', className, {
        'state-desc': value === SORT_DIR_DESC
      })}
      icon='sort-direction'
    />
  );
}

// Prop types declaration
// ================================================================
DirectionIndicator.propTypes = {
  id: PT.string,
  name: PT.string.isRequired,
  onChange: PT.func.isRequired,
  className: PT.string,
  disabled: PT.bool,
  value: sortDirectionShape.isRequired
};
