// Required libraries
// ================================================================
import React from 'react';
import cn from 'classnames';
import PT from 'prop-types';

// Hooks, constants and utils
// ================================================================
import { LABEL_COLOR_KEYS } from '../../const';

// Styles
// ================================================================
import './style.css';

// Component declaration
// ================================================================
export default function NavigationList({ className, data, component, base }) {
  const Component = component;
  return (
    <nav className={cn('NavigationList', className)}>
      {data.map(
        ({ href, icon, name, abbr, label, description, exact, current }) => (
          <Component
            key={href}
            href={base + href}
            icon={icon}
            current={current}
            exact={exact}
            name={name}
            abbr={abbr}
            description={description}
            label={label}
          />
        )
      )}
    </nav>
  );
}

// PropTypes declaration
// ================================================================
NavigationList.propTypes = {
  className: PT.string,
  base: PT.string,
  component: PT.func.isRequired,
  data: PT.arrayOf(
    PT.shape({
      className: PT.string,
      icon: PT.string,
      name: PT.string.isRequired,
      current: PT.bool,
      exact: PT.bool,
      description: PT.string,
      abbr: PT.string,
      href: PT.string.isRequired,
      label: PT.oneOf(LABEL_COLOR_KEYS)
    })
  )
};

NavigationList.defaultProps = {
  base: ''
};
