// Required libraries
// ================================================================
import React from 'react';
import PT from 'prop-types';
import { useSelector, shallowEqual } from 'react-redux';

// Store imports
// ================================================================
import { getDashboardsArray } from '../Store/Dashboards';

// Component to render
// ================================================================
import NavigationList from '../Components/NavigationList';

// Component declaration
// ================================================================
export default function DashboardNavModule({ component, base }) {
  // Get dashboards for navigation
  // ================================================================
  const data = useSelector(getDashboardsArray(), shallowEqual);

  return (
    <NavigationList
      base={base}
      data={data}
      className='DashboardNav'
      component={component}
    />
  );
}

// PropTypes declaration
// ================================================================
DashboardNavModule.propTypes = {
  component: PT.func.isRequired,
  base: PT.string
};
