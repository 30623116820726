// Required libraries
// ================================================================
import React, { useCallback, useState, useEffect } from 'react';
import PT from 'prop-types';
import { useSelector } from 'react-redux';

// Store imports
// ================================================================
import useDataFiltering from 'trendolizer-ui/build/hooks/useDataFiltering';

// Trendolizer UI imports
// ================================================================
import Dialog from 'trendolizer-ui/build/Dialog';
import Button from 'trendolizer-ui/build/Button';
import TextInput from 'trendolizer-ui/build/TextInput';

// Styles
// ================================================================
import './style.css';

// Component declaration
// ================================================================
export default function EntityBrowserDialog(props) {
  const {
    dialogId,
    show,
    title,
    name,
    onClose,
    value,
    disabled,
    selector,
    mapper,
    ItemComponent,
    closeOnSubmit,
    maxWidth,
    maxHeight
  } = props;

  const onSubmit = props.onChange;

  // Currently selected items
  // ================================================================
  const [selected, setSelected] = useState(value || []);

  // Add item to selection
  // ================================================================
  const addToSelected = useCallback(
    ({ id }) => setSelected((old) => [...old, id]),
    []
  );

  // Remove item from selection
  // ================================================================
  const removeFromSelected = useCallback(
    ({ id }) => setSelected((old) => old.filter((v) => v !== id)),
    []
  );

  // Initiate filtering functionality for a search field
  // ================================================================
  const { getFilters, filter, inputValue } = useDataFiltering('name');

  // Data is sourced from Redux store with filters applied
  // ================================================================
  const data = useSelector(
    selector(filter, (item) => ({
      ...mapper(item),
      selected: !!selected[item.id]
    }))
  );

  // OnChange wrapper, designed to provide selection to outer context
  // ================================================================
  const submitHandler = useCallback(
    (e) => {
      e.preventDefault();
      onSubmit({ name, value: selected });
      if (closeOnSubmit) {
        onClose();
      }
    },
    [onSubmit, name, selected, onClose, closeOnSubmit]
  );

  useEffect(() => {
    setSelected(value || []);
  }, [value]);

  return (
    <Dialog
      id={dialogId}
      show={show}
      title={title}
      className='EntityBrowser'
      maxWidth={maxWidth}
      maxHeight={maxHeight}
      onClose={onClose}
      footerCloseButton
      footer={
        <Button appearance='accent' onClick={submitHandler} text='Submit' />
      }
    >
      <form className='EntityBrowser-refinery'>
        <TextInput
          id='EntityBrowser-search'
          className='EntityBrowser-search'
          name='search'
          icon='search'
          type='text'
          value={inputValue}
          onChange={getFilters}
          placeholder='Type to search'
        />
      </form>
      <ul className='EntityBrowser-list'>
        {data.map(({ id, name, ...rest }) => {
          const isSelected = selected.includes(id);
          return (
            <ItemComponent
              {...rest}
              id={id}
              key={id}
              name={name}
              disabled={disabled}
              selected={isSelected}
              onClick={isSelected ? removeFromSelected : addToSelected}
              action={isSelected ? removeFromSelected : addToSelected}
              actionIcon={isSelected ? 'remove-from-list' : 'plus'}
              actionTitle={
                isSelected ? 'Deselect this item' : 'Select this item'
              }
            />
          );
        })}
      </ul>
    </Dialog>
  );
}

EntityBrowserDialog.defaultProps = {
  title: 'Browse entity',
  maxWidth: '540px',
  maxHeight: '80vh',
  closeOnSubmit: true,
  mapper: ({ id, name }) => ({ id, name })
};

EntityBrowserDialog.propTypes = {
  dialogId: PT.string.isRequired,
  setId: PT.number,
  show: PT.bool.isRequired,
  title: PT.string,
  name: PT.string.isRequired,
  disabled: PT.bool,
  value: PT.arrayOf(PT.number).isRequired,
  onClose: PT.func.isRequired,
  onChange: PT.func.isRequired,
  selector: PT.func.isRequired,
  mapper: PT.func,
  ItemComponent: PT.func.isRequired,
  closeOnSubmit: PT.bool,
  maxWidth: PT.string,
  maxHeight: PT.string
};
