import isValidString from 'trendolizer-ui/build/util/isValidString';
import { sortStringToObject, composeSortParam } from './sorting';

export const MEASUREMENT_OPTIONS = [
  {
    value: 'likes',
    label: 'Facebook likes'
  },
  // {
  //   value: 'shares',
  //   label: 'Facebook shares'
  // },
  {
    value: 'comments',
    label: 'Facebook comments'
  },
  {
    value: 'pins',
    label: 'Pins'
  },
  {
    value: 'tweets',
    label: 'Tweets'
  },
  {
    value: 'votes_video',
    label: 'Video likes'
  },
  {
    value: 'views_video',
    label: 'Video views'
  },
  {
    value: 'comments_video',
    label: 'Video comments'
  }
];

export const AVAILABLE_OPTIONS = [
  {
    value: 'title',
    label: 'Title',
    isDisabled: true
  },
  {
    value: 'domain',
    label: 'Domain'
  },
  {
    value: 'url',
    label: 'Url',
    isDisabled: true
  },
  {
    value: 'found',
    label: 'Found'
  },
  {
    value: 'author',
    label: 'Author'
  },
  {
    value: 'description',
    label: 'Description'
  },
  {
    value: 'image',
    label: 'Image'
  },
  {
    value: 'wide_image',
    label: 'Wide Image'
  },
  {
    value: 'graphs',
    label: 'Graphs'
  },
  ...MEASUREMENT_OPTIONS
];

export const INTERNAL = ['id', 'hash', 'language'];

export const VIRTUAL = ['wide_image', 'graphs'];

export const INIT_VALUE = [
  'title',
  'url',
  'found',
  'author',
  'image',
  'likes',
  //'shares',
  'comments',
  'tweets',
  'description'
];

export const DISPLAY_PRESETS = {
  gallery: INIT_VALUE,
  deck: INIT_VALUE,
  short: INIT_VALUE
};

export const MULTI_DISPLAY_PREFIXES = ['rate', 'maxrate', 'hotness'];

export const MULTI_DISPLAY_PARAMS = [
  'likes',
  //'shares',
  'pins',
  'comments',
  'tweets',
  'votes_video',
  'views_video',
  'comments_video'
];

export function isPreset(fields) {
  return isValidString(fields) && DISPLAY_PRESETS[fields];
}

export function castPreset(fields) {
  if (isPreset(fields)) {
    return [...DISPLAY_PRESETS[fields]];
  }
  return fields;
}

export const composePrefixedParam = (param) => {
  return [
    param,
    ...MULTI_DISPLAY_PREFIXES.map((prefix) =>
      composeSortParam({ prefix, param })
    )
  ];
};

export function composeQueryParam(settings, sort) {
  const { param } = sortStringToObject(sort);
  const fieldSet = new Set(castPreset(settings));
  const multiParamsSet = new Set(MULTI_DISPLAY_PARAMS);
  VIRTUAL.forEach((param) => {
    fieldSet.delete(param);
  });
  fieldSet.add(param);
  const result = [...fieldSet].reduce(
    (acc, field) => {
      acc.push(field);
      if (multiParamsSet.has(field)) {
        MULTI_DISPLAY_PREFIXES.forEach((prefix) => {
          acc.push(composeSortParam({ prefix, param: field }));
        });
      }
      return acc;
    },
    [...INTERNAL]
  );
  return result.join(',');
}

export function composeColumnParam(fields) {
  if (isPreset(fields)) {
    return castPreset(fields);
  } else if (Array.isArray(fields)) {
    return [...new Set(fields)]
      .filter((key) => key.indexOf('reddit') < 0)
      .map((field) => field.replace(/_+/, '_'));
  } else {
    return [...INIT_VALUE];
  }
}
