import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import runIfExists from 'trendolizer-ui/build/util/runIfExists';
import get from 'trendolizer-ui/build/util/get';
import { fetchUsers } from './Store/Users';
import { pickUnfetchedUsers, localStorageGet, localStorageSet } from './util';
import { NEW_ITEM_KEYWORD } from './const';
import { cast } from './validator';

const STATE_IDLE = (prev) => ({
  loading: false,
  error: null,
  success: false,
  result: undefined,
  called: prev ? prev.called : 0
});

const STATE_LOADING = ({ called }) => ({
  loading: true,
  error: null,
  success: false,
  result: undefined,
  called: called + 1
});

const STATE_DONE =
  (mixin) =>
  ({ called }) => ({
    loading: false,
    error: null,
    result: undefined,
    called,
    ...mixin
  });

export function useAction(resolver, opts = {}) {
  const { onSuccess, validation } = opts;
  // Inner action state
  // ===========================================================
  const [{ loading, called, error, success, result }, setState] =
    useState(STATE_IDLE);

  const reset = useCallback(() => setState(STATE_IDLE), []);

  // Executor function (params)
  // ===========================================================
  const execute = useCallback(
    async (params) => {
      try {
        // Set state to loading, add called + 1
        // ===========================================================
        setState(STATE_LOADING);

        // Perform request
        // ===========================================================
        const result = await resolver(
          validation ? cast(validation, params) : params
        );

        runIfExists(onSuccess, result);

        // Set state to completed
        // ===========================================================
        setState(STATE_DONE({ result, success: true }));

        // Remember payload
        // ===========================================================
        return result;
      } catch (err) {
        // Set state to error, discard payload
        // ===========================================================
        setState(STATE_DONE({ error: err.toString(), success: false }));

        // Return void
        // ===========================================================
        return undefined;
      }
    },
    [resolver, validation, onSuccess]
  );

  // Return interface
  return [execute, { loading, called, error, result, reset, success }];
}

export const useReduxAction = (handler, dispatch, overrideArg) => {
  return useCallback(
    (args) => dispatch(handler(overrideArg !== undefined ? overrideArg : args)),
    [handler, dispatch, overrideArg]
  );
};

export const usePopState = (callback, initState = null) => {
  const [state, setState] = useState(initState);
  const updater = useCallback(
    (e) => {
      setState(callback ? callback(e) : e);
    },
    [callback]
  );
  useEffect(() => {
    window.addEventListener('popstate', updater);
    return () => window.removeEventListener('popstate', updater);
  }, [updater]);

  return [state];
};

export const useManagementRouting = () => {
  const { push } = useHistory();

  const { params, path, url } = useRouteMatch();

  const [basePath] = /^\/[a-z]+/.exec(path);

  return {
    push: push,
    url,
    path,
    basePath,
    id: params.id,
    isCreate: params.id === NEW_ITEM_KEYWORD,
    navigateToScreen: useCallback(() => {
      push(basePath);
    }, [push, basePath]),
    navigateToId: useCallback(
      (arg) => {
        let id = null;
        if (typeof arg === 'number' || typeof arg === 'string') {
          id = arg;
        } else if (arg && arg.rowData) {
          id = arg.rowData.id;
        } else if (arg && arg.id) {
          id = arg.id;
        }
        if (!id) {
          throw new Error(
            `Id not found within function arguments, ${JSON.stringify(
              arg
            )} was provided. It should be direct Number, or {id} or {rowData:{id}}`
          );
        }
        return push(basePath + '/' + id);
      },
      [push, basePath]
    )
  };
};

export const useBoolState = (init = false) => {
  const [state, setState] = useState(init);

  const open = useCallback((e) => {
    e && e.preventDefault();
    setState(true);
  }, []);

  const hide = useCallback((e) => {
    e && e.preventDefault();
    setState(false);
  }, []);

  return { state, open, hide };
};

export const useSimpleState = (init = null) => {
  const [state, setState] = useState(init);

  const set = useCallback((v) => {
    setState(v);
  }, []);

  const reset = useCallback(() => {
    setState(init);
  }, [init]);

  return [state, set, reset];
};

export const useManagementForm = (params) => {
  const { entity, createResolver, updateResolver, selector } = params;
  const dispatch = useDispatch();

  const { navigateToScreen, navigateToId, isCreate, id } =
    useManagementRouting();

  const values = useSelector(selector(id));

  const resolver = isCreate ? createResolver : updateResolver;

  const title = isCreate
    ? `Create new ${entity}`
    : `Edit ${entity}: ${values.name}`;

  const onSubmit = useCallback(
    async (params) => {
      const result = await dispatch(resolver(params));
      if (isCreate && get(result, 'payload.id', null)) {
        navigateToId(result.payload.id);
      }
      return result;
    },
    [dispatch, isCreate, resolver, navigateToId]
  );

  return {
    onSubmit,
    values,
    title,
    dispatch,
    onClose: navigateToScreen
  };
};

export const useManagementDatatable = (params) => {
  const { selector, id, initColumns } = params;
  const KEY = 'TR_DATATABLE_COLUMNS';

  const [columns, setColumns] = useState(
    localStorageGet(KEY, {})[id] || initColumns
  );

  const [{ filter }, setFilter] = useState({ filter: null });

  const dispatch = useDispatch();

  const data = useSelector(selector(filter));

  const rowGetter = useCallback(({ index }) => data[index], [data]);

  const checkOwners = useCallback(
    (args) => {
      const ids = pickUnfetchedUsers(data, args);
      dispatch(fetchUsers({ ids }));
    },
    [dispatch, data]
  );

  useEffect(() => {
    const value = localStorageGet(KEY, {});
    localStorageSet(KEY, { ...value, [id]: columns });
  }, [id, columns]);

  return {
    rowGetter,
    setFilter,
    setColumns,
    filter,
    columns,
    count: data.length,
    dispatch,
    checkOwners
  };
};

export const useBodyEvent = (evt, handler) => {
  useEffect(() => {
    document.body.addEventListener(evt, handler);
    return () => {
      document.body.removeEventListener(evt, handler);
    };
  }, [handler]);
};
