import { useContext } from 'react';
import get from 'trendolizer-ui/build/util/get';
import FormContext from './context';

export const useForm = () => {
  const { onSubmit, onReset } = useContext(FormContext);
  return {
    onSubmit,
    onReset
  };
};

export const useField = (name, fallback = '') => {
  const { values, setValue, disabled } = useContext(FormContext);
  const formProps = {
    name,
    disabled,
    value: get(values, name, fallback),
    onChange: setValue
  };

  return formProps;
};
