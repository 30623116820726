// Required libraries
// ================================================================
import React from 'react';
import PT from 'prop-types';

// Proptype declarations and constants
// ================================================================
import { MULTI_DISPLAY_PARAMS } from 'shared/DisplaySettings';
import { resultTableStats } from 'shared/proptypes';

// Component declaration
// ================================================================
export function ResultTable(props) {
  const { className, ...rest } = props;
  return (
    <table className={className}>
      <tbody>
        <tr>
          <th>Param</th>
          <th>Value</th>
          <th>Rate</th>
          <th>Max</th>
          <th>Hotness</th>
        </tr>
        {MULTI_DISPLAY_PARAMS.map((param) =>
          rest[param] !== undefined ? (
            <tr key={param} data-key={param}>
              <td>{param}</td>
              <td>{rest[param] || 0}</td>
              <td>{rest[`rate_${param}`] || 0}</td>
              <td>{rest[`maxrate_${param}`] || 0}</td>
              <td>{rest[`hotness_${param}`] || 0}</td>
            </tr>
          ) : null
        )}
      </tbody>
    </table>
  );
}

export default React.memo(ResultTable);

// PropTypes Declaration
// ================================================================
ResultTable.propTypes = {
  /** CSS class for root element */
  className: PT.string.isRequired,
  /** Result measurements to display */
  ...resultTableStats
};
