// Required libraries
// ================================================================
import React from 'react';
import PT from 'prop-types';

// Trendolizer UI imports
// ================================================================
import TextInput from 'trendolizer-ui/build/TextInput';

// Component imports
// ================================================================
import { Field } from '../Form';
import DialogForm from '../DialogForm';

const initialValues = {
  username: '',
  password: ''
};

// Component declaration
// ================================================================
export default function LoginModal(props) {
  const { dialogId, session, description, title, onSubmit, onClose } = props;

  return (
    <DialogForm
      dialogId={dialogId}
      show={!session}
      maxWidth='480px'
      maxHeight='auto'
      zIndex='calc(var(--layout-z-overlay) + 2)'
      title={title}
      onSubmit={onSubmit}
      onClose={onClose}
      values={initialValues}
      className='LoginModal'
      successText='Logged in successfully'
      submitBtnText='Log-in'
    >
      {description ? (
        <div className='layout-cell'>
          <p>{description}</p>
        </div>
      ) : null}
      <div className='layout-cell'>
        <Field
          id='username'
          name='username'
          type='text'
          label='Username'
          required
          placeholder='Your username or email'
          component={TextInput}
        />
      </div>
      <div className='layout-cell'>
        <Field
          id='password'
          name='password'
          label='Password'
          type='password'
          required
          placeholder='Type password here'
          component={TextInput}
        />
      </div>
    </DialogForm>
  );
}

// PropTypes declaration
// ================================================================
LoginModal.propTypes = {
  dialogId: PT.string.isRequired,
  session: PT.bool,
  title: PT.string,
  description: PT.string,
  maxWidth: PT.string,
  hideAfter: PT.number,
  onClose: PT.func,
  onSubmit: PT.func.isRequired
};
