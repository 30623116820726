// Required libraries
// ================================================================
import React, { useCallback } from 'react';
import PT from 'prop-types';
import cn from 'classnames';

// Styles
// ================================================================
import './style.css';

// Component declaration
// ================================================================
export default function LayoutSidebar(props) {
  const { id, children, expanded, onDoubleClick, className } = props;

  const dblClickWrapper = useCallback(
    (e) => {
      e.target.classList.toggle('state-collapsed');
      onDoubleClick();
    },
    [onDoubleClick]
  );

  return (
    <aside
      role='aside'
      id={id}
      className={cn('LayoutSidebar', className, {
        'state-collapsed': !expanded
      })}
      onDoubleClick={dblClickWrapper}
    >
      {children}
    </aside>
  );
}

// Proptypes declaration
// ================================================================
LayoutSidebar.propTypes = {
  id: PT.string.isRequired,
  className: PT.string,
  expanded: PT.bool,
  onDoubleClick: PT.func,
  children: PT.node
};
