import React from 'react';
import PT from 'prop-types';

import { useField } from './hooks';

export default function Field(props) {
  const { id, name, component, defaultValue, children, ...rest } = props;

  const { disabled, ...field } = useField(name, defaultValue);
  const Component = component;

  return (
    <Component disabled={disabled} {...rest} {...field} id={id}>
      {children}
    </Component>
  );
}

Field.propTypes = {
  id: PT.string.isRequired,
  name: PT.string.isRequired,
  component: PT.func.isRequired,
  defaultValue: PT.any,
  children: PT.node
};
