import pick from 'trendolizer-ui/build/util/pick';
import omit from 'trendolizer-ui/build/util/omit';
import invalid from 'trendolizer-ui/build/util/invalid';

import createAction from './action';
import { TENANT } from './types';
import createReducer, { updateStore } from './reducer';
import { getStore } from './selector';

// Initial "blank" state
// ================================================================
export const initState = {
  id: null,
  name: '',
  fullname: 'fullname',
  email: '',
  email_bcc: [],
  image: '',
  position: 'position',
  header: '',
  footer: '',
  is_admin: 0,
  status: 'inactive'
};

// Selectors
// ================================================================
export const getTenant = getStore(TENANT.KEY);

export const getTenantInfo = (state) =>
  pick(getTenant(state), 'id', 'fullname', 'image', 'position');

export const getFullanme = (state) => getTenant(state).fullname;

export const getPrimaryEmail = (state) => getTenant(state).email;

export const getRecipients = (omitPrimary) => (state) => {
  const { email, email_bcc } = getTenant(state);
  return omitPrimary ? [...email_bcc] : [email, ...email_bcc];
};

// Actions
// ================================================================
export const fetchTenant = createAction(TENANT.FETCH, ({ api }) =>
  api.get('user')
);

export const logIn = createAction(TENANT.LOGIN, ({ api }, params) =>
  api.get('login', params).then(() => api.get('user'))
);

export const logOut = createAction(TENANT.LOGOUT, async ({ api }, params) => {
  await api.get('logout', params);
  return { success: true };
});

export const updateTenant = createAction(
  TENANT.UPDATE,
  async ({ api }, params) => {
    return {
      payload: await api.put('user', omit(params, 'name')),
      message: 'Your settings was updated successfully.'
    };
  }
);

export const createTenant = createAction(
  TENANT.CREATE,
  async ({ api }, params) => {
    const { success } = await api.post('user', params);
    return {
      payload: { fullname: params.name },
      message: success
    };
  }
);

export const updateTenantBcc = createAction(
  TENANT.UPDATE,
  async ({ api, getState }, { value }) => {
    invalid(
      Array.isArray(value) && value.every((s) => s && s.length),
      'To update tenant recipients, please provide array of string values'
    );
    const { id, email } = getTenant(getState());
    const email_bcc = value.filter((i) => i !== email);
    const payload = await api.put('user', {
      id,
      email_bcc
    });

    return {
      payload,
      message: `User recipients list was update successfully.`
    };
  }
);

// Default module export
// ================================================================
const tenantSchema = {
  id: '!number',
  name: '!string',
  fullname: '!string',
  email: '!email',
  email_bcc: ['recipient'],
  image: 'string',
  position: 'string',
  header: 'string',
  footer: 'string',
  is_admin: '!numbool',
  status: 'oneof|active,inactive'
};
export default {
  key: TENANT.KEY,
  // Schema of entity for data normalization
  // ================================================================
  dataschema: {
    [TENANT.CREATE]: { fullname: 'string' },
    [TENANT.FETCH]: tenantSchema,
    [TENANT.UPDATE]: tenantSchema,
    [TENANT.LOGIN]: tenantSchema,
    [TENANT.LOGOUT]: { success: 'bool' }
  },
  // Reducer function
  // ================================================================
  reducer: createReducer(
    {
      [TENANT.CREATE]: updateStore(),
      [TENANT.FETCH]: updateStore(),
      [TENANT.UPDATE]: updateStore(),
      [TENANT.LOGIN]: updateStore(),
      [TENANT.LOGOUT]: updateStore(initState)
    },
    initState
  )
};
