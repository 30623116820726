import React from 'react';
import PT from 'prop-types';

import { useForm } from './hooks';

export default function Form({ id, children, className, ...props }) {
  const form = useForm();
  return (
    <form {...props} {...form} id={id} className={className}>
      {children}
    </form>
  );
}

Form.propTypes = {
  id: PT.string.isRequired,
  className: PT.string,
  children: PT.node
};
