import React, { useCallback } from 'react';
import PT from 'prop-types';
import cn from 'classnames';
import Select from 'trendolizer-ui/build/Select';
import { castStrToOption } from 'shared/util';
import {
  SORT_PREFIXES,
  SORT_PARAMS,
  sortStringToObject,
  composeSortParam
} from 'shared/sorting';

// Styles
// ================================================================
import './style.css';

const sortPrefixesOptions = SORT_PREFIXES.map(castStrToOption);
const sortParamOptions = SORT_PARAMS.map(castStrToOption);

export default function SortingDropdown(props) {
  const { id, name, value, onChange, className, disabled, closeOthersOnOpen } =
    props;
  const { prefix, param } = sortStringToObject(value);

  const onPrefixChange = useCallback(
    ({ target }) =>
      onChange({
        id,
        name,
        value: composeSortParam({ prefix: target.value, param })
      }),
    [id, name, param, onChange]
  );

  const onParamChange = useCallback(
    ({ target }) =>
      onChange({
        id,
        name,
        value: composeSortParam({ prefix, param: target.value })
      }),
    [id, name, prefix, onChange]
  );
  return (
    <div className={cn('SortingDropdown', className)}>
      <Select
        id={`${id}-prefix`}
        name={`${name}-prefix`}
        placeholder='Prefix'
        className='SortingDropdown-prefix'
        options={sortPrefixesOptions}
        disabled={disabled || param === 'found'}
        value={prefix}
        closeOthersOnOpen={closeOthersOnOpen}
        clearable
        onChange={onPrefixChange}
      />
      <Select
        id={`${id}-param`}
        name={`${name}-param`}
        placeholder='Sort parameter'
        className='SortingDropdown-param'
        options={sortParamOptions}
        disabled={disabled}
        closeOthersOnOpen={closeOthersOnOpen}
        value={param}
        onChange={onParamChange}
      />
    </div>
  );
}

SortingDropdown.propTypes = {
  id: PT.string,
  className: PT.string,
  name: PT.string.isRequired,
  value: PT.string.isRequired,
  disabled: PT.bool,
  closeOthersOnOpen: PT.bool,
  onChange: PT.func.isRequired
};
