import PT from 'prop-types';
import { EMAIL_REGEX } from 'trendolizer-ui/build/const';
import {
  LABEL_COLOR_KEYS,
  SOURCE_TYPES,
  STATE_ACTIVE,
  STATE_INACTIVE,
  NUM_BOOL_FALSE,
  NUM_BOOL_TRUE
} from './const';

function createCustomPropType(validate) {
  const typeChecker = (props, key, cmp, loc, prop) => {
    if (props[key] !== null && props[key] !== undefined) {
      validate(props, key, cmp, loc, prop);
    }
  };
  typeChecker.isRequired = (props, key, cmp, loc, prop) => {
    if (props[key] !== null || props[key] !== undefined) {
      return new Error(
        `The '${prop}' marked as required in:'${cmp}'. But it's value is ${props[key]}`
      );
    } else {
      validate(props, key, cmp, loc, prop);
    }
  };
  return typeChecker;
}

export const email = createCustomPropType((props, key, cmp, loc, prop) => {
  if (!props[key] || !EMAIL_REGEX.test(props[key])) {
    return new Error(
      `Invalid prop '${prop}' supplied to:'${cmp}'. Validation failed.`
    );
  }
});

export const slackHook = createCustomPropType((props, key, cmp, loc, prop) => {
  if (props[key].indexOf('hooks.slack.com') < 0) {
    return new Error(
      `Invalid prop '${prop}' supplied to:'${cmp}'. Validation failed.`
    );
  }
});

export const telegramChat = createCustomPropType(
  (props, key, cmp, loc, prop) => {
    if (props[key].indexOf('t.me/') < 0) {
      return new Error(
        `Invalid prop '${prop}' supplied to:'${cmp}'. Validation failed.`
      );
    }
  }
);

export const numBool = PT.oneOf([NUM_BOOL_FALSE, NUM_BOOL_TRUE]);

export const entityStatus = PT.oneOf([STATE_ACTIVE, STATE_INACTIVE]);

export const simpleTenantShape = {
  id: PT.number,
  fullname: PT.string,
  image: PT.string,
  position: PT.string
};

export const fullTenantShape = {
  id: PT.number,
  fullname: PT.string,
  image: PT.string,
  email: email,
  email_bcc: PT.arrayOf(PT.string),
  position: PT.string,
  name: PT.string,
  header: PT.string,
  footer: PT.string,
  is_admin: numBool,
  status: entityStatus
};

export const serviceShape = {
  get: PT.func.isRequired,
  post: PT.func.isRequired,
  put: PT.func.isRequired,
  delete: PT.func.isRequired
};

export const localStorageShape = {
  onChange: PT.func.isRequired,
  removeListener: PT.func.isRequired,
  get: PT.func.isRequired,
  set: PT.func.isRequired
};

export const dashboard = {
  id: PT.number,
  name: PT.string.isRequired,
  columns: PT.arrayOf(PT.number),
  label: PT.oneOf(LABEL_COLOR_KEYS)
};

export const column = {
  id: PT.number.isRequired,
  name: PT.string.isRequired,
  open: numBool,
  data: PT.shape({}),
  display_settings: PT.arrayOf(PT.string)
};

export const resultTableStats = {
  /** Facebook shares */
  likes: PT.number,
  rate_likes: PT.number,
  maxrate_likes: PT.number,
  hotness_likes: PT.string,
  /** Facebook shares */
  shares: PT.number,
  rate_shares: PT.number,
  maxrate_shares: PT.number,
  hotness_shares: PT.string,
  /** Facebook comments */
  comments: PT.number,
  rate_comments: PT.number,
  maxrate_comments: PT.number,
  hotness_comments: PT.string,
  /** Tweets */
  tweets: PT.number,
  rate_tweets: PT.number,
  maxrate_tweets: PT.number,
  hotness_tweets: PT.string,
  /** Views video */
  views_video: PT.number,
  rate_views_video: PT.number,
  maxrate_views_video: PT.number,
  hotness_views_video: PT.string,
  /** Votes video */
  votes_video: PT.number,
  rate_votes_video: PT.number,
  maxrate_votes_video: PT.number,
  hotness_votes_video: PT.string,
  /** Comments video */
  comments_video: PT.number,
  rate_comments_video: PT.number,
  maxrate_comments_video: PT.number,
  hotness_comments_video: PT.string,
  /** Pinterest pins */
  pins: PT.number,
  rate_pins: PT.number,
  maxrate_pins: PT.number,
  hotness_pins: PT.string
};

export const result = {
  /** Identifiers */
  id: PT.number.isRequired,
  hash: PT.string.isRequired,
  /** Text content */
  title: PT.string.isRequired,
  truncatedTitle: PT.string.isRequired,
  description: PT.string,
  truncatedDescription: PT.string,
  /** Media */
  image: PT.string,
  /** Meta information */
  url: PT.string.isRequired,
  author: PT.string,
  found: PT.string,
  domain: PT.string,
  /** Status info */
  favorite: numBool.isRequired,
  ignore: numBool.isRequired,
  /** Tabular data */
  ...resultTableStats
};

export const sourceType = PT.oneOf(Object.values(SOURCE_TYPES));
