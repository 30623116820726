import createAction from './action';
import createReducer, { updateKey, mergeStore } from './reducer';
import { getStore } from './selector';
import { DB_SETTINGS_KEY } from 'shared/const';
import { APP, TENANT } from './types';

const REDUX_KEY = APP.KEY.toLowerCase();

// Selectors
// ================================================================
export const getAppStore = getStore(REDUX_KEY);

export const getSessionStatus = (state) => getAppStore(state).sessionStatus;

export const getNetworkError = (state) => getAppStore(state).networkError;

export const getSettingsModalStatus = (state) =>
  getAppStore(state).settingsOpen;

export const getSidebarState = (state) => getAppStore(state).sidebarState;

export const getItemForDeleting = (state) =>
  getAppStore(state).deletePending || {};

// Actions
// ================================================================
export const setSessionStatus = createAction(APP.SESSION_STATUS);

export const networkError = createAction(APP.NETWORK_ERROR);

export const setSettingsDialogStatus = createAction(APP.SETTINGS_DIALOG_STATUS);

export const resetPassword = createAction(
  APP.PASSRESET_SENT,
  ({ api }, params) => api.get('reset_password', params)
);

export const setSidebarState = createAction(
  APP.SIDEBAR_STATE,
  ({ apiv2 }, value) => apiv2.setItem(DB_SETTINGS_KEY, { sidebarState: value })
);

export const setDeletePending = createAction(APP.SET_DELETE_PENDING);

export const fetchSettings = createAction(APP.FETCH_SETTINGS, ({ apiv2 }) =>
  apiv2.getOrCreateItem(DB_SETTINGS_KEY, { sidebarState: false })
);

// Default module export
// ================================================================
export default {
  key: REDUX_KEY,
  // Reducer function
  // ================================================================
  reducer: createReducer(
    {
      [APP.SETTINGS_DIALOG_STATUS]: updateKey('settingsOpen'),
      [TENANT.LOGIN]: updateKey('sessionStatus', true),
      [APP.SESSION_STATUS]: updateKey('sessionStatus'),
      [APP.NETWORK_ERROR]: updateKey('networkError'),
      [APP.PASSRESET_SENT]: updateKey('resetPassSent'),
      [APP.SIDEBAR_STATE]: mergeStore,
      [APP.SET_DELETE_PENDING]: updateKey('deletePending'),
      [APP.FETCH_SETTINGS]: (state, payload) => ({ ...state, ...payload })
    },
    {
      networkError: null,
      resetPassSent: false,
      sidebarState: false,
      settingsOpen: false,
      sessionStatus: true,
      deletePending: null
    }
  )
};
