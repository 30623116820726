import userimage_fallback from 'img/user-placeholder.svg';

// Required libraries
// ================================================================
import React from 'react';
import PT from 'prop-types';
import cn from 'classnames';

// Trendolizer ui imports
// ================================================================
import Image from 'trendolizer-ui/build/Image';

// Styles
// ================================================================
import './style.css';

// Component declaration
// ================================================================
export default function UserInfo(props) {
  const { children, image, position, fullname, className } = props;
  return (
    <div className={cn('UserInfo', className)}>
      <Image
        className='UserInfo-image'
        src={image}
        ratio='1'
        alt={fullname}
        fallback={userimage_fallback}
      />
      <b className='UserInfo-name'>{fullname}</b>
      <small className='UserInfo-position'>{position}</small>
      {children}
    </div>
  );
}

// Proptypes declaration
// ================================================================
UserInfo.propTypes = {
  className: PT.string,
  image: PT.string,
  fullname: PT.string,
  position: PT.string,
  children: PT.node
};

UserInfo.defaultProps = {
  fullname: 'User not found',
  position: ''
};
