import React from 'react';
import PT from 'prop-types';
import cn from 'classnames';
import Image from 'trendolizer-ui/build/Image';

// Styles
// ================================================================
import './style.css';

export default function NoItems(props) {
  const { className, title, children, image, imageRatio, listItem } = props;

  const img = (
    <Image className='NoItems-image' src={image} ratio={imageRatio} />
  );
  const titleEl = <h5 className='NoItems-title'>{title}</h5>;

  if (listItem) {
    return (
      <li className={cn('NoItems', className)} aria-description='no items'>
        {img}
        {titleEl}
        {children}
      </li>
    );
  }

  return (
    <div className={cn('NoItems', className)} aria-description='no items'>
      {img}
      {titleEl}
      {children}
    </div>
  );
}

NoItems.defaultProps = {
  listItem: false,
  imageRatio: '1'
};

NoItems.propTypes = {
  listItem: PT.bool,
  className: PT.string,
  children: PT.node,
  title: PT.string,
  image: PT.string,
  imageRatio: PT.string
};
