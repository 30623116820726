import isFunc from 'trendolizer-ui/build/util/isFunc';

export class AsyncAction {
  constructor({ type, resolver, params }) {
    this.type = type;
    this.params = params;
    this.resolver = resolver;
  }

  execute = async (inject) => {
    const result = await this.resolver(inject, this.params);
    return {
      type: this.type,
      payload: result.payload || result,
      message: result.message || null
    };
  };

  abort = () => {};
}

export default function createAction(type, resolver) {
  // Action creator to use
  // ================================================================
  return function actionCreator(payload) {
    // Check if react or dom event was accidentally passed as action argument
    // ================================================================
    if (
      typeof payload === 'object' &&
      payload !== null &&
      ('_dispatchListeners' in payload || payload instanceof Event)
    ) {
      console.warn(
        `Event was passed as action param, make sure you call action creator propertly, pass only requrired params`,
        payload
      );
      return undefined;
    }

    // Plain action creator just pass-throuhg argument
    // ================================================================
    if (!isFunc(resolver)) {
      return {
        type,
        payload
      };
    }

    // Async action creator (with thunk inject)
    // ================================================================
    return new AsyncAction({ type, resolver, params: payload });
  };
}
