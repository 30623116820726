import './style.css';

// Required libraries
// ================================================================
import React from 'react';
import PT from 'prop-types';
import cn from 'classnames';

// Hooks, constants and utils
// ================================================================
import { LABEL_COLOR_KEYS } from '../../const';

// Trendolizer ui components
// ================================================================
import Icon from 'trendolizer-ui/build/Icon';

// Component declaration
// ================================================================
export default function NavigationLink(props) {
  const { className, icon, name, label, abbr, href, description, current } =
    props;

  // Defime label color through CSS variable
  // ================================================================
  const labelColor = label ? `var(--color-label-${label})` : undefined;

  /* const isCurrent =
    window.location.href.length - window.location.href.indexOf(href) ===
    href.length; */

  return (
    <a
      href={current ? null : href}
      className={cn('NavigationLink', className, {
        'state-current': current
      })}
      style={{
        '--NavigationLink-labelcolor': labelColor
      }}
      title={description}
    >
      {icon ? (
        <Icon className='NavigationLink-icon' icon={icon} />
      ) : (
        <span className={cn('NavigationLink-abbr')}>{abbr}</span>
      )}
      <span className='NavigationLink-text is-ellipsis'>{name}</span>
    </a>
  );
}

// Proptypes declaration
// ================================================================
NavigationLink.propTypes = {
  className: PT.string,
  icon: PT.string,
  name: PT.string.isRequired,
  abbr: PT.string,
  description: PT.string,
  current: PT.bool,
  href: PT.string.isRequired,
  label: PT.oneOf(LABEL_COLOR_KEYS)
};
