// Required libraries
// ================================================================
import React, { useCallback } from 'react';
import PT from 'prop-types';
import cn from 'classnames';

// Hook, utils and const imports
// ================================================================
import { useAction } from '../../hooks';

// Trendolizer UI imports
// ================================================================
import LinearProgress from 'trendolizer-ui/build/LinearProgress';
import Dialog from 'trendolizer-ui/build/Dialog';
import Button from 'trendolizer-ui/build/Button';
import Tabs from 'trendolizer-ui/build/Tabs';

// Components imports
// ================================================================
import FormProvider from '../Form';
import StatusMessage from '../StatusMessage';

// Styles
// ================================================================
import './style.css';
import { useEffect } from 'react';

// Component declaration
// ================================================================
export default function DialogForm(props) {
  const {
    dialogId,
    title,
    show,
    uniqueId,
    maxWidth,
    maxHeight,
    onSubmit,
    validation,
    className,
    zIndex,
    headerContent,
    submitBtnText,
    useTabs,
    values,
    onChange,
    onClose,
    children
  } = props;

  // Async form logic handling
  // ================================================================
  const [submitHandler, { loading, error, success, reset, result }] = useAction(
    onSubmit,
    { validation }
  );

  // Auto hide success message
  // ================================================================
  useEffect(() => {
    let timer;
    if (success) {
      timer = setTimeout(reset, 1500);
    }
    return () => clearTimeout(timer);
  }, [reset, success]);

  // Reset form handler on Dialog close
  // ================================================================
  const closeHandler = useCallback(() => {
    onClose();
    reset();
  }, [reset, onClose]);

  return (
    <Dialog
      id={`${dialogId}-dialog`}
      show={show}
      className={cn('DialogForm', className)}
      maxWidth={maxWidth}
      zIndex={zIndex}
      maxHeight={maxHeight}
      title={title.replace('%name%', values.name)}
      wrapContent={false}
      footerCloseButton
      header={
        <>
          {headerContent}
          <LinearProgress loading={loading} success={success} />
          {error || success ? (
            <StatusMessage
              className={cn('DialogForm-message', {
                'tabs-offset': useTabs
              })}
              warning={error}
              success={success && result ? result.message : null}
              onClick={reset}
            />
          ) : null}
        </>
      }
      footer={
        <Button
          disabled={loading}
          type='submit'
          appearance='accent'
          form={dialogId}
          text={submitBtnText}
        />
      }
      onClose={closeHandler}
    >
      <FormProvider
        formId={dialogId}
        onSubmit={submitHandler}
        onReset={reset}
        disabled={loading}
        uniqueId={uniqueId || (values && values.id) || null}
        values={values}
        renderForm
        onChange={onChange}
        formClassName='DialogForm-wrapper'
      >
        {useTabs ? (
          <Tabs
            className='DialogForm-tabs'
            itemClassName='DialogForm-content layout-grid'
          >
            {children}
          </Tabs>
        ) : (
          <div className='DialogForm-content layout-grid'>{children}</div>
        )}
      </FormProvider>
    </Dialog>
  );
}

// PropTypes declaration
// ================================================================
DialogForm.propTypes = {
  dialogId: PT.string.isRequired,
  title: PT.string.isRequired,
  show: PT.bool.isRequired,
  uniqueId: PT.oneOfType([PT.string, PT.number]),
  onChange: PT.func,
  onSubmit: PT.func.isRequired,
  onClose: PT.func.isRequired,
  maxWidth: PT.string,
  maxHeight: PT.string,
  useTabs: PT.bool,
  zIndex: PT.oneOfType([PT.string, PT.number]),
  validation: PT.objectOf(
    PT.oneOfType([
      PT.string,
      PT.objectOf(
        PT.oneOfType([PT.string, PT.objectOf(PT.string), PT.arrayOf(PT.string)])
      ),
      PT.arrayOf(PT.string)
    ])
  ),
  children: PT.oneOfType([
    PT.node,
    PT.arrayOf(PT.shape({ title: PT.string, component: PT.node }))
  ]),
  className: PT.string,
  submitBtnText: PT.string,
  headerContent: PT.node,
  values: PT.shape({ id: PT.number, name: PT.string })
};

DialogForm.defaultProps = {
  submitBtnText: 'Submit'
};
