import isDateValid from 'trendolizer-ui/build/util/isDateValid';
import isEmail from 'trendolizer-ui/build/util/isEmail';
import isPlainObject from 'trendolizer-ui/build/util/isPlainObject';

export const bool = (val) => {
  if (typeof val !== 'boolean') {
    return 'should be a Boolean value e.g. true|false';
  }
};

export const string = (val) => {
  if (typeof val !== 'string') {
    return 'should be a valid string';
  }
};

export const number = (val, minmax = '') => {
  const [min, max] = minmax.split(',').map((num) => parseInt(num));
  if (!Number.isSafeInteger(val)) {
    return 'should be a valid Number, (not NaN or Infinity)';
  }
  if (Number.isSafeInteger(min) && val < min) {
    return `should be a valid Number bigger or equal ${min}`;
  }
  if (Number.isSafeInteger(max) && val > max) {
    return `should be a valid Number less or equal ${max}`;
  }
};

export const numbool = (val) => {
  if (val !== 1 && val !== 0) {
    return 'should be either 0 or 1';
  }
};

export const numboolindt = (val) => {
  if (val !== 1 && val !== 0 && val !== 'auto') {
    return 'should be either 0, 1 or "auto"';
  }
};

export const oneof = (val, options) => {
  if (!options.split(',').some((v) => v === val)) {
    return `should be equal to one of given values ${options}`;
  }
};

export const email = (val) => {
  if (!isEmail(val)) {
    return 'should be a valid email';
  }
};

export const recipient = (val) => {
  if (
    !isEmail(val) &&
    !val.indexOf('t.me/') < 0 &&
    !val.indexOf('hooks.slack.com/') < 0
  ) {
    return 'should be a valid email telegramm or slack hook.';
  }
};

export const isodate = (val) => {
  if (!isDateValid(val)) {
    return 'should be a valid ISO-8601 date string yyyy-MM-ddTHH:mm:ss.SSSZ';
  }
};

export const regex = (val, regex) => {
  if (!new RegExp(regex).test(val)) {
    return `should match this expression ${regex}`;
  }
};

export const object = (val, type) => {
  if (
    !isPlainObject(val) ||
    !(type && Object.values(val).every((v) => typeof v === type))
  ) {
    return `should be plain object with every value as [${type}]`;
  }
};
