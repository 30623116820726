import no_recipients from 'img/no_recipients.svg';

// Required libraries
// ================================================================
import React, { useCallback, useState } from 'react';
import PT from 'prop-types';
import cn from 'classnames';

// Hook, utils and const imports
// ================================================================
import { RECIPIENT_TYPE_EMAIL } from '../../const';
import { getRecipientType } from '../../util';
import runOnKey from 'trendolizer-ui/build/util/runOnKey';

// Trendolizer UI imports
// ================================================================
import Button from 'trendolizer-ui/build/Button';
import TextInput from 'trendolizer-ui/build/TextInput';

// Components imports
// ================================================================
import { EntityRecipient } from '../Entity';
import NoItems from '../NoItems';

// Styles
// ================================================================
import './style.css';

const initInputState = {
  type: RECIPIENT_TYPE_EMAIL,
  error: null,
  value: ''
};

const sameItemError = (oldState) => ({
  ...oldState,
  error: 'Seems recipient with same address is already in the list.'
});

const invalidItemError = (oldState) => ({
  ...oldState,
  error: 'Recipient should be a valid email, slack hook or telegram channel'
});

export default function RecipientList(props) {
  const {
    id,
    name,
    value,
    selected,
    children,
    loading,
    placeholder,
    onItemClick,
    className,
    message,
    noItemsText,
    onAddItem,
    onRemoveItem,
    itemActionIcon,
    disabled,
    error,
    label
  } = props;

  const [newItem, setNewItem] = useState(initInputState);

  // Separate logic for new item creation
  // storing value and detect type
  // =====================================================================
  const onInputChange = useCallback(({ value }) => {
    const type = getRecipientType(value);
    setNewItem({ type, value });
  }, []);

  // Separate logic for item creation
  // =====================================================================
  const addItem = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      if (value.includes(newItem.value)) {
        setNewItem(sameItemError);
      } else if (!newItem.type) {
        setNewItem(invalidItemError);
      } else {
        onAddItem({ newItem: newItem.value, value: [...value, newItem.value] });
        setNewItem(initInputState);
      }
    },
    [onAddItem, value, newItem]
  );

  // Separate logic for item removal
  // =====================================================================
  const removeItem = useCallback(
    ({ id }) =>
      onRemoveItem({ deleting: id, value: value.filter((i) => i !== id) }),
    [onRemoveItem, value]
  );

  // Makup return
  // =====================================================================
  return (
    <div
      className={cn('RecipientList', className, {
        'state-error': newItem.error || error
      })}
    >
      <div className='form-heading'>
        <h4 className='form-heading-title'>{label}:</h4>
      </div>
      <ul className='RecipientList-values vertical-list'>
        {value.length ? (
          value.map((recipient) => (
            <EntityRecipient
              id={recipient}
              key={recipient}
              name={recipient}
              selected={selected === recipient}
              disabled={disabled || loading}
              action={removeItem}
              onClick={onItemClick}
              actionIcon={itemActionIcon}
              actionTitle='Remove this entry recipient list'
            />
          ))
        ) : (
          <NoItems
            title={noItemsText}
            image={no_recipients}
            imageRatio='630/512'
          />
        )}
      </ul>
      <div className='RecipientList-form'>
        <TextInput
          id={id}
          name={name}
          type='text'
          icon={newItem.type || RECIPIENT_TYPE_EMAIL}
          className='RecipientList-input'
          placeholder={placeholder}
          value={newItem.value}
          disabled={disabled || loading}
          onChange={onInputChange}
          onKeyDown={runOnKey('Enter', addItem)}
        />
        <Button
          appearance='accent'
          disabled={disabled}
          loading={loading}
          onClick={addItem}
          className='RecipientList-submit'
          text={loading ? null : 'add'}
          title='Add new recipient'
        />
      </div>
      <p className='RecipientList-message'>
        {newItem.error || error || message}
      </p>
      {children}
    </div>
  );
}

RecipientList.defaultProps = {
  itemActionIcon: 'cross',
  placeholder: 'Enter recipient addrees',
  noItemsText: 'No recipients in a list \n use a form below to create one'
};

RecipientList.propTypes = {
  label: PT.string,
  message: PT.string,
  error: PT.string,
  className: PT.string,
  noItemsText: PT.node,
  onItemClick: PT.func,
  selected: PT.string,
  loading: PT.bool,
  itemActionIcon: PT.string,
  itemAction: PT.func,
  onAddItem: PT.func.isRequired,
  onRemoveItem: PT.func.isRequired,
  id: PT.string.isRequired,
  disabled: PT.bool,
  children: PT.node,
  name: PT.string.isRequired,
  placeholder: PT.string,
  value: PT.arrayOf(PT.string)
};

export function RecipientFormField(props) {
  const { id, name, disabled, value, onChange, ...rest } = props;

  const changeWrapper = useCallback(
    (change) => onChange({ name, value: change.value }),
    [onChange, name]
  );

  return (
    <RecipientList
      {...rest}
      id={id}
      name={name}
      disabled={disabled}
      onAddItem={changeWrapper}
      onRemoveItem={changeWrapper}
      value={value}
    />
  );
}

RecipientFormField.propTypes = {
  id: PT.string.isRequired,
  name: PT.string.isRequired,
  disabled: PT.bool.isRequired,
  value: PT.arrayOf(PT.string).isRequired,
  onChange: PT.func.isRequired
};
