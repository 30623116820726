// Generic constants
// ===================================================
export const NBSP = ' ';

export const NEW_ITEM_KEYWORD = 'new';

export const NUM_BOOL_TRUE = 1;

export const NUM_BOOL_FALSE = 0;

export const NUM_BOOL_OPTS = [NUM_BOOL_FALSE, NUM_BOOL_TRUE];

export const STATE_ACTIVE = 'active';

export const STATE_INACTIVE = 'inactive';

export const SCROLLBAR_CSS_VAR = 'layout-scrollbar-size';

// Custom events
// ===================================================
export const GRID_REBUILD_EVENT = 'grid-rebuild';

export const GRID_SCROLL_EVENT = 'grid-scrollto';

export const RESULT_SCROLL_EVENT = 'result-scrollto';

// Localstorage and Redux keys
// ===================================================
export const DB_SETTINGS_KEY = 'settings';

export const DB_DASHBOARDS_KEY = 'dashboards';

export const ORDER_KEY = '@order';

export const DATA_KEY = '@data';

export const RESULT_DETAILS_KEY = 'DETAILS';

export const API_STORE_KEY = '@@TR_API';

// Navigation paths
// ===================================================
export const BASE_URL = process.env.TRENDOLIZER_ENDPOINT;

export const MANAGEMENT_URL = '/management';

export const MANAGEMENT_PATHS = {
  COLUMNS: '/columns',
  SOURCES: '/sources',
  FINGERPRINTS: '/fingerprints',
  ALERTS: '/alerts',
  REPORTS: '/reports',
  DASHBOARDS: '/dashboards',
  AUTHORS: '/authors'
};

export const DASHBOARD_URL = '/dashboard';

export const DASHBOARD_PARAM = 'd';

export const AUTHENTICATION_URL = '/authentication';

export const RECOVER_PASS_URL = '/recover';

export const LOGIN_URL = '/login';

export const SIGNUP_URL = '/signup';

// Element ID's
// ===================================================
export const ROOT_ID = 'root';

export const TENANT_SETTINGS_ID = 'tenant-settings';

export const LOGIN_DIALOG_ID = 're-login';

export const CREATE_COLUMN_DIALOG_ID = 'create-column';

export const ASIDE_NAV_ID = 'app-main-navigation';

export const DELETE_CONFIRMATION_ID = 'delete-confirmation';

export const DASHBOARD_DND_ID = 'dashboard';

export const FULL_RESULT_ID = 'full-result';

export const LOADING_OVERLAY_ID = 'loading-overlay';

export const DASHBOARD_GRID_ID = 'dashboard-grid';

export const RECIPIENT_REMOVE_DIALOG_ID = 'recipient-remove-confirm';

export const SEARCH_RESULTS_DIALOG_ID = 'search-results-dialog';

// Dashboard params
// ===================================================
export const DASHBOARD_MAX_COUNT = 12;

export const LABEL_COLOR_KEYS = [
  'darkred',
  'red',
  'orange',
  'yellow',
  'green',
  'darkgreen',
  'darkturquoise',
  'deepskyblue',
  'blue',
  'violet',
  'purple',
  'pink'
];

export const DEFAULT_DASHBOARD = {
  id: 0,
  name: 'Default',
  abbr: 'DE',
  label: 'red',
  description:
    'This is default dashboard. All columns created may be observerd here. This dashboard cannot be deleted or changed. This is dashboard you used on previous versions of an app.',
  width: 380,
  current: false,
  href: `${DASHBOARD_URL}?${DASHBOARD_PARAM}=0`,
  columns: []
};

// Recipient params
// ===================================================
export const RECIPIENT_TYPE_EMAIL = 'email';

export const RECIPIENT_TYPE_SLACK = 'slack';

export const RECIPIENT_TYPE_TELEGRAM = 'telegram';

// Notification params
// ===================================================
export const NOTIFICATION_TIMEOUT = 4000;

export const NOTIFICATION_MAX_COUNT = 50;

// Various entity params
// ===================================================
export const SOURCE_ITEM_SIZE = 62;

export const APP_ENTITY_TYPES = Object.freeze({
  APP: 'APP',
  COLUMN: 'COLUMN',
  RESULT: 'RESULT',
  DASHBOARD: 'DASHBOARD',
  NOTIFICATION: 'NOTIFICATION',
  ALERT: 'ALERT',
  REPORT: 'REPORT',
  USER: 'USER',
  TENANT: 'TENANT',
  FINGERPRINT: 'FINGERPRINT',
  SOURCESET: 'SOURCESET',
  SOURCE: 'SOURCE',
  AUTHOR: 'AUTHOR'
});

export const FNPT_TYPE_MAP = Object.freeze({
  IP: 'ipaddress',
  'Google Adsense': 'adsense',
  'Google Analytics': 'googleanalytics',
  'Facebook App ID': 'facebookapp'
});

export const RESULT_TYPE_KEYS = [
  'is_image',
  'is_video',
  'is_gallery',
  'is_facebook'
];

export const LANGUAGES = [
  { label: 'English', value: 'English' },
  { label: 'French', value: 'French' },
  { label: 'German', value: 'German' },
  { label: 'Dutch', value: 'Dutch' },
  { label: 'Spanish', value: 'Spanish' },
  { label: 'Korean', value: 'Korean' },
  { label: 'Arabic', value: 'Arabic' },
  { label: 'Chinese', value: 'Chinese' },
  { label: 'Hindi', value: 'Hindi' },
  { label: 'Japanese', value: 'Japanese' },
  { label: 'Greek', value: 'Greek' },
  { label: 'Any', value: 'Any' },
  { label: 'Unknown', value: 'Unknown' },
  { label: 'Undetected', value: 'Undetected' }
];

export const FITER_TYPES = ['MIN', 'MAX'];

export const SOURCE_TYPES = {
  FACEBOOK: 'Facebook',
  TWITTER: 'Twitter',
  RSS: 'RSS',
  TELEGRAM: 'Telegram',
  REDDIT: 'Reddit',
  HTML: 'HTML',
  YOUTUBE: 'Youtube',
  DUMMY: 'DUMMY',
  API: 'API',
  FOUND_ON: 'FOUND ON',
  PHOAX: 'PHOAX'
};
