import isFunc from 'trendolizer-ui/build/util/isFunc';
import omit from 'trendolizer-ui/build/util/omit';
import set from 'trendolizer-ui/build/util/set';
import get from 'trendolizer-ui/build/util/get';

import { mergeArray } from '../../util';
import { ORDER_KEY, DATA_KEY } from '../../const';

// Regular expression to test key for values to replace
// ================================================================
const KEY_REGEX = /\$([a-zA-Z0-9_-]+)/g;

/**
 * Generate new "blank" store shape with predefined keys
 * @returns {Object} New "empty" store shape
 */
export const getInitStore = (extend = {}) => ({
  ...extend,
  [ORDER_KEY]: [],
  [DATA_KEY]: {}
});

/**
 * Helper function that allows to compose store key using data from a payload:
 * replaces $param with payload.param value e.g. something.$id.data => something.345.data
 * @param {Object} payload action payload
 * @param {String} string key descriptor
 * @returns {String} Composed key
 */
export const getStoreKey = (payload, string) =>
  string
    .replace('DATA', DATA_KEY)
    .replace('ORDER', ORDER_KEY)
    .replace(KEY_REGEX, (raw, key) => {
      if (!Object.hasOwnProperty.call(payload, key)) {
        throw new Error(`
    Key ${key} not found in action payload. Payload keys: ${Object.keys(
          payload
        ).join(',')}.
    Check that payload has correct property to extract key from.`);
      }
      return payload[key];
    });

/**
 * Create reducer with given map of action processors
 * @param {Object} actionMap key,handler map of processors
 * @param {Object|Array|Function} initState initial state
 * @returns {Function} Redux-compatible reducer function
 */
export default function createReducer(config = {}, initState = getInitStore()) {
  // Actual reducer function used in store construction
  // ================================================================
  return (state = initState, action) => {
    if (isFunc(config[action.type])) {
      // Run handler for action type if found in config
      // ================================================================
      const newState = Array.isArray(state) ? [...state] : { ...state };
      return config[action.type](newState, action.payload);
    } else if (isFunc(config.default)) {
      // Run [default] case handler if found
      // ================================================================
      return config.default(state, action);
    }
    // Just return existing state otherwise
    // ================================================================
    return state;
  };
}

/**
 * Uses payload to replace state entirely, typical operation GET
 * @param {Object} state current Redux store state
 * @param {Array(Object)} payload New collection of data to be set
 * @param {Object} initState initial state to be used as reduce base
 * @returns {Object} new Redux state produced
 */
export const defaultSetAll = (_, payload) => {
  return payload.reduce((acc, item) => {
    acc[ORDER_KEY].push(item.id);
    acc[DATA_KEY][item.id] = item;
    return acc;
  }, getInitStore());
};

/**
 * Uses payload to add new entity one or more to collection, typical operation POST
 * @param {Object} state current Redux store state
 * @param {Object|Array(Object)} payload New entity to be added to current collection
 * @returns {Object} new Redux state produced
 */
export const defaultCreate = (state, payload) => {
  const items = Array.isArray(payload) ? payload : [payload];
  return items.reduce((acc, item) => {
    acc[ORDER_KEY].unshift(item.id);
    acc[DATA_KEY][item.id] = item;
    return acc;
  }, state);
};

/**
 * Uses payload to merge subset of data to collection, typical operation FETCH_MORE or offset pagination
 * @param {Object} state current Redux store state
 * @param {Object|Array(Object)} payload New entity to be added to current collection
 * @returns {Object} new Redux state produced
 */
export const defaultMerge = (state, payload) => {
  const order = payload.map((item) => {
    state[DATA_KEY][item.id] = item;
    return item.id;
  });
  state[ORDER_KEY] = mergeArray(state[ORDER_KEY], order);
  return state;
};

/**
 * Uses payload to update data of given entity in a collection, typical operation PUT
 * @param {Object} state current Redux store state
 * @param {Object} payload New data to update particular item in current collection
 * @returns {Object} new Redux state produced
 */
export const defaultSetOne = (state, payload) => ({
  ...state,
  [DATA_KEY]: {
    ...state[DATA_KEY],
    [payload.id]: payload
  }
});

/**
 * Uses payload to update data of given entity in a collection, typical operation PATCH
 * BUT it NOT OVERRIDES BUT MERGES data: usefull for partial updates
 * @param {Object} state current Redux store state
 * @param {Object} payload New data to update particular item in current collection
 * @returns {Object} new Redux state produced
 */
export const defaultMergeOne = (state, payload) => ({
  ...state,
  [DATA_KEY]: {
    ...state[DATA_KEY],
    [payload.id]: {
      ...(state[DATA_KEY][payload.id] || {}),
      ...payload
    }
  }
});

/**
 * Uses id in a payload to remove given entity from a collection, typical operation DELETE
 * @param {Object} state current Redux store state
 * @param {Number} payload.id id of entity to remove
 * @returns {Object} new Redux state produced
 */
export const defaultUnsetOne = (state, { id }) => ({
  ...state,
  [ORDER_KEY]: state[ORDER_KEY].filter((item) => item !== id),
  [DATA_KEY]: omit(state[DATA_KEY], id)
});

/**
 * Update particular key from given Redux subtree,
 * usefull for atomary domifications
 * @param {String} key key descriptor to update
 * @param {Function(state, payload)|Any|undefined} updater update descriptor, has several modes:
 * - if [undefined] - key is replaced by action payload
 * - if [function] - key is replaced by value returned by function provided, function receives current key value and action payload
 * - if any non-undefined value is provided - key replaced by this value no matter of action payload
 * @returns {Object} new Redux state produced
 */
export const updateKey = (key, updater) => (state, payload) => {
  const storeKey = getStoreKey(payload, key);

  let value = payload;

  if (isFunc(updater)) {
    value = updater(get(state, storeKey), payload);
  } else if (updater !== undefined) {
    value = updater;
  }

  return set({ ...state }, storeKey, value);
};

/**
 * Remove particular key from given Redux subtree,
 * usefull for atomary domifications
 * @param {String} key key descriptor to remove
 * @returns {Object} new Redux state produced
 */
export const removeKey = (key) => (state, payload) => {
  const storeKey = getStoreKey(payload, key);
  return omit(state, storeKey);
};

/**
 * Replace whole store with value given, if not, [payload] will be used.
 * @param {Any} replacer new store value
 * @returns {Object|Array} new store
 */
export const updateStore = (updater) => (state, payload) => {
  return updater !== undefined ? updater : payload;
};

export const mergeStore = (state, payload) => ({ ...state, ...payload });
